import { BusinessOperationsKeys } from "../../pages/BusinessOperations/BusinessOperations"
import { commercialGrainElevatorFacilitySelectedPayload, 
  farmingOperationCommercialGrainSelectedPayload, 
  feedMillingProcessingFacilitySelectedPayload,
  noFormSelectedPayload,
  otherAgriBusinessSelectedPayload } from "../../types/gev/BusinessOperationsData"

const getFullJsonPayload = (operationKey: BusinessOperationsKeys) => {
  switch (operationKey) {
    case "commercialGrainElevatorFacility":
      return commercialGrainElevatorFacilitySelectedPayload
    case "feedMillingProcessingFacility":
        return feedMillingProcessingFacilitySelectedPayload
    case "otherAgriBusiness":
        return otherAgriBusinessSelectedPayload
    case "farmingOperationCommercialGrain":
        return farmingOperationCommercialGrainSelectedPayload
    case "noFormSelected":
        return noFormSelectedPayload
    default:
        return noFormSelectedPayload
  }
}

export default getFullJsonPayload;