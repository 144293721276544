import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../components/common/Button/Button';
import LandingBanner from '../../../components/LandingBanner/LandingBanner';
import { RootState } from '../../../redux/reducers/root';
import "./Overview.css";
import Header from '../../../layouts/Header/Header';
import { getIncomeFile } from '../../../redux/actions/file/file';
import { ProfileOverviewState } from '../../../redux/reducers/getProfile/getProfileOverviewReducer';
import { FileState } from '../../../redux/reducers/getFile/getFileReducer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../layouts/Footer/Footer';
import LoadingSpinner from '../../../components/common/LoadingSpinner/LoadingSpinner';
import { GeneratePDFPayload } from '../../../types/pdf/GeneratePDFPayload';
import { getProfileDetails, resetGetProfileDetails } from '../../../redux/actions/profile/profile';
import { GetElsLookupsState } from '../../../redux/reducers/getLookups/getElsLookupsReducer';
import { getElsLookups } from '../../../redux/actions/lookups/lookups';
import { ProfileDetailsState } from '../../../redux/reducers/getProfile/getProfileDetailsReducer';
import { setReadOnly } from '../../../redux/actions/readOnly/readOnly';

export default function Overview(): JSX.Element {

    const { t } = useTranslation('transition');
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const fileState: FileState = useSelector((state: RootState) => state.fileReducer.file);
    const [downloading, setDownloading] = useState(false);
    const [isProfileDetailsReqSent, setIsProfileDetailsReqSent] = useState(false);
    const [reviewing, setReviewing] = useState(false)
    const rollNumber = localStorage.getItem('rollNumber') as string

    useEffect(() => {
        dispatch(resetGetProfileDetails())
    }, [dispatch]);

    const reviewReturn = (e: React.MouseEvent) => {
        e.preventDefault();
        if (profileOverview.profile.current_campaign) {
            setIsProfileDetailsReqSent(true);
            dispatch(getProfileDetails(profileOverview.profile.current_campaign.currentSnapshotId));
        }
        setReviewing(true);
        dispatch(setReadOnly(true))

        if (!elsLookupsState.success) {
            dispatch(getElsLookups());
        }
    }

    useEffect(() => {
        if (isProfileDetailsReqSent && !profileDetails.loading && reviewing) {
            if (elsLookupsState.success && profileDetails.success) {
                localStorage.removeItem('incomeReturnType'); // remove incomeReturnType for WIP as it's irrelevant
                localStorage.setItem('rollNumber', rollNumber)
                localStorage.setItem('profileId', String(profileOverview.profile.profile_id));
                localStorage.setItem('activeCampaignYear', String(profileOverview.profile.current_campaign.year));
                localStorage.setItem('campaignId', String(profileOverview.profile.current_campaign.campaignId));
                localStorage.setItem('currentSnapshotId', String(profileOverview.profile.current_campaign.currentSnapshotId));
                setReviewing(false);
                navigate('/portal')
            } else if (!profileDetails.success) {
                toast.error("Failed to retrieve profile details");
            }
        }
    }, [isProfileDetailsReqSent, profileDetails, elsLookupsState, reviewing, navigate, profileOverview, rollNumber]);


    const downloadPdf = (e: React.MouseEvent) => {
        e.preventDefault();
        const payload: GeneratePDFPayload = {
            language: i18n.language,
            rollNumber: localStorage.getItem("rollNumber") as string,
            profileId: profileOverview.profile.profile_id,
            campaignId: profileOverview.profile.current_campaign.campaignId,
            campaignYear: profileOverview.profile.current_campaign.year,
            snapshotRequestType: "2"
        }
        console.log("download file payload", payload);
        dispatch(getIncomeFile(payload));
        setDownloading(true);
    }

    useEffect(() => {
        if (downloading && fileState.loading === false) {
            setDownloading(false);
            if (fileState.status === 200) {
                const base64string = "data:application/pdf;base64," + fileState.pdf;
                const downloadLink = document.createElement("a");
                downloadLink.href = base64string;
                downloadLink.download = localStorage.getItem('rollNumber') + "_" + profileOverview.profile.current_campaign.year + "_PIER.pdf";
                downloadLink.click();
            } else {
                toast.error(fileState.message);
            }
        }
    }, [downloading, fileState, profileOverview.profile.current_campaign.year]);

    return (<>
        <Header showAutoSave={false} isInternalUser={false} />
        <LandingBanner />
        <div className='continue-body-container'>
            <LoadingSpinner loading={fileState.loading || profileDetails.loading || reviewing || profileOverview.loading}>
                <div className='continue-body'>
                    <h1>{t("continue.welcome")}!</h1>
                    <p>{t("overview.submitted")}</p>
                            <p className="strong">{rollNumber}</p>
                            <p>
                        <div className="transition-btn-group">
                            <Button onClick={e => downloadPdf(e)} type="submit" text={t("overview.download")} />
                            <div className='button-group'>
                                <Button onClick={e => reviewReturn(e)} type="secondary" text={t("overview.view-return")} />
                                <Button onClick={() => navigate('/landing')} type="secondary" text={t("return")} />
                            </div>
                        </div>
                            </p>
                </div>
            </LoadingSpinner>
        </div>
        <div className="start-footer">
            <Footer />
        </div>
    </>);
}
