import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OperationFieldProps } from '../../types/gev/BusinessOperationFormProps';

import { useReadOnly } from '../../utils/ReadOnlyContext';
import "./GEV.css";
import { CampaignThroughputData, FarmingOperationCommercialGrainClass, PercentageOfThroughput } from '../../types/gev/BusinessOperationsData';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function isFarmingOperation(dataset: any): dataset is FarmingOperationCommercialGrainClass {
    return dataset && 
    typeof dataset.selected === 'boolean' &&
    'percentageOfThroughput' in dataset
}

export default function GEVPercentageOfThroughput(props: OperationFieldProps): JSX.Element {
    const { t } = useTranslation('gev')
    const { formData, setFormData } = useBusinessOperationsState();
    const { isReadOnly } = useReadOnly()
    const colKeys = ["yearValue", "owner", "nonOwner", "totalThroughput"];
    const activeCampaignYear = Number(localStorage.getItem("activeCampaignYear"));
    const [potData, setPotData] = useState<PercentageOfThroughput | undefined>(isFarmingOperation(formData) ? formData.percentageOfThroughput : {
        campaignYearOne: { yearValue: activeCampaignYear - 3, owner: null, nonOwner: null, totalThroughput: null },
        campaignYearTwo: { yearValue: activeCampaignYear - 2, owner: null, nonOwner: null, totalThroughput: null },
        campaignYearThree: { yearValue: activeCampaignYear - 1, owner: null, nonOwner: null, totalThroughput: null },
    })

    const handlePotChange = (e: React.ChangeEvent<HTMLInputElement>, yearKey: keyof PercentageOfThroughput, yearObj: CampaignThroughputData)  => {
        e.preventDefault();
        let ownerValue = null;
        if (!e.currentTarget.value || e.currentTarget.value === "") {
            ownerValue = null;
        }
        else if (Number(e.currentTarget.value) < 0) {
            ownerValue = 0;
        }
        else if (Number(e.currentTarget.value) > 100) {
            ownerValue = 100;
        }
        else {
            ownerValue = Number(e.currentTarget.value);
        }
        
        const newData: CampaignThroughputData = {
            yearValue: yearObj.yearValue,
            owner: ownerValue,
            nonOwner: ownerValue ? (100 - ownerValue) : 0,
            totalThroughput: ownerValue ? (ownerValue + (100 - ownerValue)) : 0
        }

        setPotData(prevData => {
            const dataset = prevData as PercentageOfThroughput;
            return {
                ...dataset,
                [yearKey]: newData
            }
        });
    }

    useEffect(() => {
        setFormData((prevData) => {
            const dataset = prevData.farmingOperationCommercialGrain;
            dataset.percentageOfThroughput = potData;
            return {
                ...prevData,
                [props.operation]: dataset
            }
        });
    }, [potData, props.operation, setFormData ])

    return (
        <tr className='gev-input-tr'>
            <td className='index'>
                {props.index && props.index + "."}
            </td>
            <td>
                <div className='gev-input-label'>{t(props.operation + "." + props.name + ".label")}</div>
                <table width={"100%"} className='percentageOfThroughput' role="none">
                    <tbody>
                        <tr key={"heading"}>
                            {
                                colKeys.map((colKey, index) => {
                                    if (index === 0) {
                                        return <td key={colKey} width={"10%"}>{t(props.operation + "." + props.name + "." + colKey)}</td>
                                    } else if (index === 1) {
                                        return <td key={colKey} width={"50%"}>{t(props.operation + "." + props.name + "." + colKey)}</td>
                                    } else {
                                        return <td key={colKey} width={"20%"}>{t(props.operation + "." + props.name + "." + colKey)}</td>
                                    }
                                })
                            }
                        </tr>
                        {potData && Object.entries(potData as PercentageOfThroughput).map(([yearKey, yearObj]) => {
                                return (
                                    <tr key={yearKey}>
                                        <td key={yearObj.yearValue}>{yearObj.yearValue}</td>
                                        <td key={`${yearKey}-owner`}>
                                            <input type="number"
                                                id={yearKey}
                                                name={yearKey}
                                                value={yearObj.owner ?? ""}
                                                aria-label={t(props.operation + "." + props.name)}
                                                autoComplete="off"
                                                disabled={isReadOnly}
                                                onChange={(e) => handlePotChange(e, yearKey as keyof PercentageOfThroughput, yearObj)}
                                                className={props.hasErrors ? 'form-error' : ''}
                                            /> %
                                        </td>
                                        <td key={`${yearKey}-nonOwner`}>{yearObj.nonOwner} %</td>
                                        <td key={`${yearKey}-totalThroughput`}>{yearObj.totalThroughput} %</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </td>
        </tr>
    )
}
