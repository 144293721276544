import { PUT_ASSESSMENT_INFO, RESET_PUT_ASSESSMENT_INFO } from "../../actionTypes/actionsTypes"
import defaultAsyncReducer from "../../defaultReducer"
import { AppAction, LocalAction } from "../../types"

const initialState = {
  loading: false,
  success: false,
  error: false,
  message: "",
  errors: []
}

function putAssessmentInfoReducer(state = initialState, action: AppAction | LocalAction) {
  if (action.type === RESET_PUT_ASSESSMENT_INFO) {
    return initialState
  } else {
    return defaultAsyncReducer(state, action as AppAction, PUT_ASSESSMENT_INFO)
  }
}

export default putAssessmentInfoReducer