import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
// import { faPrint } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { getIncomeFile } from '../../redux/actions/file/file'
import { getFullSnapshotData } from '../../redux/actions/snapshot/getFullSnapshotData'
import { getLookups } from '../../redux/actions/lookups/lookups'
import { setSaveResultSuccess } from '../../redux/actions/saveData/saveData'
// import { INTERNAL_IN_PROGRESS } from '../../redux/actionTypes/actionsTypes'
import { RESET_GET_FULL_SNAPSHOT_DATA } from '../../redux/actionTypes/actionsTypes'
import { RootState } from '../../redux/reducers/root'
// import { FileState } from '../../redux/reducers/getFile/getFileReducer'
import { LookupsState } from '../../redux/reducers/getLookups/lookupsReducer'
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer'
import { SaveDataState } from '../../redux/reducers/getSaveData/saveDataReducer'
import { SnapshotState } from '../../redux/reducers/getSnapshot/getFullSnapshotDataReducer'
// import Button from '../../components/common/Button/Button'
import IncomeAttributeLookupObject from '../../types/lookups/IncomeAttributeLookupObject'
import IncomeAttributeLookupState from '../../types/lookups/IncomeAttributeLookupState'
// import { GeneratePDFPayload } from '../../types/pdf/GeneratePDFPayload'
import formatCurrency from '../../utils/formatCurrency'
import './Summary.css'

export default function Summary(): JSX.Element {
    // const { t } = useTranslation('common')
    const { i18n } = useTranslation()
    const dispatch = useDispatch()
    // const fileState: FileState = useSelector((state: RootState) => state.fileReducer.file)
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview)
    const incomeAttributeLookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes"
    const lookupState: LookupsState = useSelector((state: RootState) => state.lookupsReducer.getLookups)
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const snapshotDataState: SnapshotState = useSelector((state: RootState) => state.snapshotDataReducer.getSnapshotData)
    // const [downloading, setDownloading] = useState(false)
    // const rollNumber = localStorage.getItem('rollNumber') as string
    const snapshotId = localStorage.getItem("currentSnapshotId") as string
    const [tenantTypes, setTenantTypes] = useState<string[]>([])
    const [incomeAttributeTypeLookup, setIncomeAttributeTypeLookup] = useState<Record<string, string>[]>([])
    const [unitTypeLookup, setUnitTypeLookup] = useState<Record<string, string>[]>([])
    const [incomeAttributeLookup, setIncomeAttributeLookup] = useState<IncomeAttributeLookupState | {}>((lookupState.success && lookupState.lookupType === incomeAttributeLookupType) ? lookupState.lookups : {})
    const [lookupMap, setLookupMap] = useState<Record<string, string[]>>({})
    const [allSet, setAllSet] = useState(false)

    useEffect(() => {
        if (incomeAttributeTypeLookup.length
            && unitTypeLookup.length
            && Object.keys(incomeAttributeLookup).length
            && snapshotDataState.status === 200) {
            setAllSet(true)
        }
    }, [incomeAttributeLookup, incomeAttributeTypeLookup.length, snapshotDataState.status, unitTypeLookup.length])

    useEffect(() => {
        if (incomeAttributeTypeLookup.length === 0) {
            dispatch(getLookups("incomeAttributeType"))
        }
        if (unitTypeLookup.length === 0) {
            dispatch(getLookups("unitType"))
        }
        if (Object.keys(incomeAttributeLookup).length === 0) {
            dispatch(getLookups(incomeAttributeLookupType))
        }
    }, [dispatch, incomeAttributeLookup, incomeAttributeLookupType, incomeAttributeTypeLookup.length, unitTypeLookup.length])

    useEffect(() => {
        if (lookupState.success) {
            if (lookupState.lookupType === "incomeAttributeType") {
                setIncomeAttributeTypeLookup(lookupState.lookups as Record<string, string>[])
            } else if (lookupState.lookupType === "unitType") {
                setUnitTypeLookup(lookupState.lookups as Record<string, string>[])
            } else if (lookupState.lookupType === incomeAttributeLookupType) {
                const lookups = lookupState.lookups as IncomeAttributeLookupState
                setIncomeAttributeLookup(lookups)
                const map = {} as Record<string, string[]>
                const lookupTypes = Object.keys(lookups)
                lookupTypes.forEach((lookupType) => {
                    const lookupTypeLookups = lookups[lookupType]
                    Object.keys(lookupTypeLookups).forEach((lookupCategory) => {
                        const lookupCategoryArray = lookupTypeLookups[lookupCategory] as IncomeAttributeLookupObject[]
                        if (profileOverview.profile.profile_type_code === "BIP" && lookupCategory === "rentalLoss") {
                            const bipRentalLossLookupType = "5"
                            lookupCategoryArray.forEach((obj) => {
                                if (!Object.keys(map).includes(bipRentalLossLookupType)) {
                                    map[bipRentalLossLookupType] = []
                                }
                                map[bipRentalLossLookupType].push(obj.incomeAttributeCode)
                            })
                        } else {
                            lookupCategoryArray.forEach((obj) => {
                                if (!Object.keys(map).includes(lookupType)) {
                                    map[lookupType] = []
                                }
                                map[lookupType].push(obj.incomeAttributeCode)
                            })
                        }
                    })
                })
                setLookupMap(map)
            }
        }
    }, [incomeAttributeLookupType, lookupState, profileOverview.profile.profile_type_code]);

    useEffect(() => {
        setTenantTypes([])
        dispatch({ type: RESET_GET_FULL_SNAPSHOT_DATA })
        dispatch(getFullSnapshotData(snapshotId))
    }, [dispatch, snapshotId])

    useEffect(() => {
        if (snapshotDataState.status === 200) {
            const data = snapshotDataState.data
            if (Object.keys(data).length > 0) {
                const tenants = data.tenants as Record<string, unknown>[]
                const allTenantTypes = tenants.map((tenant) => tenant.unitTypeCode as string)
                const tenantTypes = [...new Set(allTenantTypes)].sort()
                setTenantTypes(tenantTypes)
            }
        } else {
            if (snapshotDataState.message) {
                toast.error(snapshotDataState.message)
            }
        }
    }, [snapshotDataState])

    const calcTotalIncomeAttributes = (type: string) => {
        const data = snapshotDataState.data
        if (Object.keys(data).length > 0) {
            let total = 0
            const incomeAttributes = data.incomeAttribute as Record<string, unknown>[]
            const lookups = lookupMap[type] ? lookupMap[type] : []
            let percent159 = 0
            incomeAttributes.forEach((attr) => {
                if (lookups.includes(attr.code as string)) {
                    if (attr.code === "159" && profileOverview.profile.profile_type_code === "LTC") {
                        if (attr.percent) {
                            percent159 = attr.percent as number
                        }
                    } else if (attr.code === "50") {
                        // not count in total
                    }
                    else {
                        if (attr.value) {
                            total = total + (attr.value as number)
                        }
                    }
                }
            })
            if (percent159) {
                total = total * (100 - percent159) / 100
            }
            return total
        }
        return 0
    }

    const calcTotalTenants = (type: string) => {
        const data = snapshotDataState.data
        if (Object.keys(data).length > 0) {
            let total = 0
            const tenants = data.tenants as Record<string, unknown>[]
            tenants.forEach((tenant) => {
                if (tenant.unitTypeCode === type) {
                    total = total + 1
                }
            })
            return total
        }
        return 0
    }

    // const downloadPreviewFile = () => {
    //     const status = localStorage.getItem('campaignStatusCode')
    //     const snapshotRequestType = status === INTERNAL_IN_PROGRESS ? "3" : "2" // localStorage.getItem("isInternalUser") ? "3" : "2"
    //     const payload: GeneratePDFPayload = {
    //         language: i18n.language,
    //         rollNumber: rollNumber,
    //         profileId: Number(localStorage.getItem('profileId') as string),
    //         campaignId: Number(localStorage.getItem('campaignId') as string),
    //         campaignYear: Number(localStorage.getItem("activeCampaignYear") as string),
    //         snapshotId: Number(localStorage.getItem("currentSnapshotId") as string),
    //         snapshotRequestType: snapshotRequestType
    //     }
    //     dispatch(getIncomeFile(payload))
    //     setDownloading(true)
    // }

    // useEffect(() => {
    //     if (downloading && fileState.loading === false) {
    //         setDownloading(false)
    //         if (fileState.status === 200) {
    //             const base64string = "data:application/pdf;base64," + fileState.pdf
    //             const downloadLink = document.createElement("a")
    //             downloadLink.href = base64string
    //             downloadLink.download = rollNumber + "_" + localStorage.getItem("activeCampaignYear") as string + "_PIER.pdf"
    //             downloadLink.click()
    //         } else {
    //             if (fileState.message) {
    //                 toast.error(fileState.message)
    //             }
    //         }
    //     }
    // }, [downloading, fileState, rollNumber])

    useEffect(() => {
        if (saveData.saving) {
            dispatch(setSaveResultSuccess())
            dispatch(getLookups(incomeAttributeLookupType))
        }
    }, [dispatch, incomeAttributeLookupType, saveData.saving])

    return (
        // <div className='summary-page-container'>
        //     <FontAwesomeIcon icon={faPrint} className='print-icon' />
        //     <p className='print-wip-text'>{t('print-wip')}</p>
        //     <Button type='submit' text={t('print')} onClick={() => downloadPreviewFile()} />
        // </div>

        <div>
            {allSet && <>
                <p>Income And Expense Summary</p>
                {
                    Object.keys(lookupMap).length ?
                        <table className="table-with-border pie-table">
                            {
                                Object.keys(lookupMap).map((type) => {
                                    const incomeAttributeType = incomeAttributeTypeLookup.filter((t) => t.incomeAttributeTypeCode === type)
                                    if (incomeAttributeType.length > 0) {
                                        return <tr key={type}>
                                            <td className='padding-left-10 padding-right-10'>{incomeAttributeType[0].incomeAttributeTypeDescription}</td>
                                            <td className='padding-left-10 padding-right-10 text-right'>{formatCurrency(calcTotalIncomeAttributes(type), i18n.language)}</td>
                                        </tr>
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </table>
                        :
                        <>Not Available</>
                }
                <br />
                <br />
                <p>Tenants Summary</p>
                {
                    tenantTypes.length ?
                        <table className="table-with-border pie-table">
                            {
                                tenantTypes.map((type) => {
                                    const unitType = unitTypeLookup.filter((t) => t.unitTypeCode === type)
                                    if (unitType.length > 0) {
                                        return <tr key={type}>
                                            <td className='padding-left-10 padding-right-10'>{unitType[0].unitTypeDescription}</td>
                                            <td className='padding-left-10 padding-right-10 text-right'>{calcTotalTenants(type)}</td>
                                        </tr>
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </table>
                        :
                        <>No Tenants</>
                }
            </>
            }
        </div>
    )
}
