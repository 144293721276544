import { SAVE_RESULT, RESET_SAVE_RESULT, SET_SAVE_RESULT_SUCCESS } from "../../actionTypes/actionsTypes";
import defaultAsyncReducer from "../../defaultReducer";
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types";

export interface SaveResultState extends BaseAsyncReducerState {
  success: boolean,
  message: string,
  result?: Record<string, string>[];
}

const initialState: SaveResultState = {
  success: false,
  message: "",
  loading: false,
  error: false
}

function getSaveResultReducer(state = initialState, action: AppAction | LocalAction) {
  if (action.type === RESET_SAVE_RESULT) {
    return initialState;
  } else if (action.type === SET_SAVE_RESULT_SUCCESS) {
    return {...state, status: 200}
  } else {
    return defaultAsyncReducer(state, action as AppAction, SAVE_RESULT)
  }
  
}

export default getSaveResultReducer;