/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import { GET_FULL_SNAPSHOT_DATA, RESET_GET_FULL_SNAPSHOT_DATA } from "../../actionTypes/actionsTypes"
import defaultAsyncReducer from "../../defaultReducer"
import { AppAction, BaseAsyncReducerState, LocalAction } from "../../types"


export interface SnapshotState extends BaseAsyncReducerState {
  success: boolean,
  message: string,
  data: Record<string, unknown>
}

const initialState: SnapshotState = {
  loading: false,
  error: false,
  success: false,
  message: "",
  data: {} as Record<string, unknown>,
}

function getSnapshotDataReducer(state = initialState, action: AppAction | LocalAction) {
  if (action.type === RESET_GET_FULL_SNAPSHOT_DATA) {
    return initialState
  } else {
    return defaultAsyncReducer(state, action as AppAction, GET_FULL_SNAPSHOT_DATA)
  }
}

export default getSnapshotDataReducer