import React, { useCallback, useEffect, useState } from 'react';
import './Comment.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers/root';
import { SaveDataState } from '../../../redux/reducers/getSaveData/saveDataReducer';
import { CommentDataClass, CommentState } from '../../../redux/reducers/getComment/getCommentReducer';
import { getComment, postComment, putComment } from '../../../redux/actions/comment/comment';
import { useTranslation } from 'react-i18next';
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { useReadOnly } from '../../../utils/ReadOnlyContext';
import { setSaveResultSuccess } from '../../../redux/actions/saveData/saveData';

export default function Comment(): JSX.Element {
    const { t } = useTranslation('pie');
    const dispatch = useDispatch();
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const commentState: CommentState = useSelector((state: RootState) => state.commentReducer.comment);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const [commentId, setCommentId] = useState(-1);
    const [comment, setComment] = useState("");
    const [internalCommentId, setInternalCommentId] = useState(-1);
    const [internalComment, setInternalComment] = useState("");
    const { isReadOnly } = useReadOnly()

    useEffect(() => {
        if ((!commentState.loading && !commentState.success) 
            || (commentState.data && commentState.data.length > 0 && commentState.data[0].campaignId !== Number(localStorage.getItem('campaignId') as string)))
        {
            dispatch(getComment(Number(localStorage.getItem('campaignId') as string)));
        }
        // eslint-disable-next-line
    }, []);
    
    const findComment = useCallback((cId: number) => {
        let commentData: CommentDataClass | undefined = undefined;
        if (commentState.data) {
            commentData = (commentState.data).find(({ commentId }) => commentId === cId);
        }
        return commentData;
    }, [commentState.data]);

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getComment(Number(localStorage.getItem('campaignId') as string)));
        }
    }, [dispatch, saveResultState]);

    useEffect(() => {
        if (saveData.saving && localStorage.getItem('isInternalUser') !== 'true') {
            if (commentId < 0) {
                if (findComment(commentId)) {
                    dispatch(postComment(Number(localStorage.getItem('campaignId') as string),
                        { ...(findComment(commentId)) }));
                } else {
                    dispatch(setSaveResultSuccess())
                }
            } else {
                dispatch(putComment(Number(localStorage.getItem('campaignId') as string),
                    commentId, { ...(findComment(commentId)) }));
            }
        }
        // eslint-disable-next-line
    }, [dispatch, saveData.saving, commentId])

    useEffect(() => {
        if (saveData.saving && localStorage.getItem('isInternalUser') === 'true') {
            if (internalCommentId < 0) {
                if (findComment(internalCommentId)) {
                    dispatch(postComment(Number(localStorage.getItem('campaignId') as string),
                        { ...(findComment(internalCommentId)) }));
                }
            } else {
                dispatch(putComment(Number(localStorage.getItem('campaignId') as string),
                    internalCommentId, { ...(findComment(internalCommentId)) }));
            }
        }
    }, [dispatch, saveData.saving, internalCommentId, findComment])

    useEffect(() => {
        if (!commentState.loading && commentState.data) {
            const commentData = commentState.data.find((comment) => comment.commentTypeCode === "EXT");
            if (commentData) {
                setCommentId(commentData.commentId ?? -1);
                setComment(commentData.commentText ?? "");
            }
            const internalCommentData = commentState.data.find((comment) => comment.commentTypeCode === "INT");
            if (internalCommentData) {
                setInternalCommentId(internalCommentData.commentId ?? -1);
                setInternalComment(internalCommentData.commentText ?? "");
            }
        } else if (!commentState.loading && !commentState.success) {
            setCommentId(-1);
            setComment("");
            setInternalCommentId(-1);
            setInternalComment("");
        }
    }, [commentState.data, commentState.loading, commentState.success]);

    const updateComment = (comment: string) => {
        if (comment.length <= 5000) {
            const commentData = findComment(commentId);
            if (commentData) {
                commentData.commentText = comment;
            } else {
                const newComment = {
                    commentId: commentId,
                    commentDate: new Date().toISOString(),
                    commentTypeCode: "EXT",
                    userName: "",
                    profileId: Number(localStorage.getItem('profileId') as string),
                    campaignId: Number(localStorage.getItem('campaignId') as string),
                    commentText: comment,
                    deletedFlag: false
                }
                if (commentState.data){
                    (commentState.data).push(newComment);
                } else {
                    commentState.data = [newComment];
                }
            }
            setComment(comment);
        }
    }

    const updateInternalComment = (comment: string) => {
        if (comment.length <= 5000) {
            const commentData = findComment(internalCommentId);
            if (commentData) {
                commentData.commentText = comment;
            } else {
                const newComment = {
                    commentId: internalCommentId,
                    commentDate: new Date().toISOString(),
                    commentTypeCode: "INT",
                    userName: "",
                    profileId: Number(localStorage.getItem('profileId') as string),
                    campaignId: Number(localStorage.getItem('campaignId') as string),
                    commentText: comment,
                    deletedFlag: false
                }
                if (commentState.data){
                    (commentState.data).push(newComment);
                } else {
                    commentState.data = [newComment];
                }
            }
            setInternalComment(comment);
        }
    }

    return (<>
        <div>
            <div>
                <h3>{t("commentDescription")}</h3>
                <textarea id="comments" name="comments" cols={80} rows={12} maxLength={5000} value={comment}
                    onChange={(e) => updateComment((e.currentTarget as HTMLTextAreaElement).value)} 
                    className='comment-textbox' disabled={localStorage.getItem('isInternalUser') === 'true' || isReadOnly}/>
            </div>
            {localStorage.getItem('isInternalUser') && <>
                <div>
                    <h3>Internal comments</h3>
                    <textarea id="comments internal-comments" name="internal-comments" cols={80} rows={12} value={internalComment}
                    onChange={(e) => updateInternalComment((e.currentTarget as HTMLTextAreaElement).value)}
                    className='comment-textbox internal-comment' disabled={isReadOnly}/>
                </div>
            </>}
        </div>
    </>);
}
