import { ActionType, AsyncActionType } from "../types";

export const asyncActionType = (type: ActionType): AsyncActionType => ({
  PENDING: `${type}_PENDING`,
  SUCCESS: `${type}_SUCCESS`,
  ERROR: `${type}_ERROR`,
  RESET: `${type}_RESET`
});

/* API */
export const API_REQUEST: ActionType = 'API_REQUEST';

// Examples
export const GET_COLOUR_PALETTE: AsyncActionType = asyncActionType('GET_COLOUR_PALETTE');

// Global
export const GET_GLOBAL_SETTING: AsyncActionType = asyncActionType('GET_GLOBAL_SETTING');

// Profile Actions
export const SET_CURRENT_PORTAL_ROLL = 'SET_CURRENT_PORTAL_ROLL';
export const SET_CURRENT_PORTAL_PAGE = 'SET_CURRENT_PORTAL_PAGE';
export const SET_CURRENT_PORTAL_PROFILETYPE = 'SET_CURRENT_PORTAL_PROFILETYPE';
export const SET_CURRENT_PORTAL_OPENMENUS = 'SET_CURRENT_PORTAL_OPENMENUS';
export const SET_CURRENT_PORTAL_BREADCRUMBS = 'SET_CURRENT_PORTAL_BREADCRUMBS';

// Auth
export const VALIDATE_TOKEN: AsyncActionType = asyncActionType("VALIDATE_TOKEN");
export const VALIDATE_INTERNAL_TOKEN: AsyncActionType = asyncActionType("VALIDATE_INTERNAL_TOKEN");
export const LOG_OUT = "LOG_OUT";
// Navigation
export const CHANGES_MADE = "CHANGES_MADE";
export const RESET_CHANGES_MADE = "RESET_CHANGES_MADE"
export const SET_SAVING = 'SET_SAVING';
export const RESET_SAVING = 'RESET_SAVING';
export const SET_VALID = 'SET_VALID';
export const RESET_SAVEDATA = 'RESET_SAVEDATA';
export const SAVE_RESULT: AsyncActionType = asyncActionType('SAVE_RESULT');
export const SET_SAVE_RESULT_SUCCESS = 'SET_SAVE_RESULT_SUCCESS';
export const RESET_SAVE_RESULT = 'RESET_SAVE_RESULT';

// Read Only
export const SET_READ_ONLY = 'SET_READ_ONLY'

// Landing
export const GET_PROPERTIES: AsyncActionType = asyncActionType('GET_PROPERTIES');
export const RESET_PROPERTIES = 'RESET_PROPERTIES';
export const SET_CURRENT_PROPERTY = 'SET_CURRENT_PROPERTY';

export const SEARCH_PROFILE_BY_ROLL: AsyncActionType = asyncActionType('SEARCH_PROFILE_BY_ROLL');
export const RESET_SEARCH_PROFILE_BY_ROLL = "RESET_SEARCH_PROFILE_BY_ROLL"

// Campaign Status types
export const NEW_CREATION = 'NEW_CREATION';
export const EXTERNAL_IN_PROGRESS = 'EXTERNAL_IN_PROGRESS';
export const EXTERNAL_SUBMITTED = 'EXTERNAL_SUBMITTED';
export const INTERNAL_IN_PROGRESS = 'INTERNAL_IN_PROGRESS';
export const CAMPAIGN_COMPLETED = 'CAMPAIGN_COMPLETED';
export const UPDATE_CAMPAIGN_STATUS: AsyncActionType = asyncActionType('UPDATE_CAMPAIGN_STATUS');
export const RESET_UPDATE_CAMPAIGN_STATUS = 'RESET_UPDATE_CAMPAIGN_STATUS';
export const UPDATE_ACKNOWLEDGEMENT_FLAG: AsyncActionType = asyncActionType('UPDATE_ACKNOWLEDGEMENT_FLAG');
export const RESET_GET_FULL_SNAPSHOT_DATA = "RESET_GET_FULL_SNAPSHOT_DATA"
export const GET_FULL_SNAPSHOT_DATA: AsyncActionType = asyncActionType('GET_FULL_SNAPSHOT_DATA')

// Menu Actions
export const SET_MENU = 'SET_MENU';
export const SET_ACTIVE_MENU_ITEM = 'SET_ACTIVE_MENU_ITEM';
export const SET_NEXT_ACTIVE_MENU_ITEM = 'SET_NEXT_ACTIVE_MENU_ITEM';
export const UPDATE_MENU_ITEM_STATUS = 'UPDATE_MENU_ITEM_STATUS';

// Lookup
export const GET_LOOKUPS: AsyncActionType = asyncActionType('GET_LOOKUPS');
export const RESET_LOOKUPS = 'RESET_LOOKUPS';
export const GET_ELS_LOOKUPS: AsyncActionType = asyncActionType("GET_ELS_LOOKUPS");

// Profile
export const GET_PROFILE_OVERVIEW: AsyncActionType = asyncActionType('GET_PROFILE_OVERVIEW');
export const GET_PROFILE_DETAILS: AsyncActionType = asyncActionType('GET_PROFILE_DETAILS');
export const RESET_GET_PROFILE_DETAILS = 'RESET_GET_PROFILE_DETAILS';
export const PUT_PROFILE_DETAILS: AsyncActionType = asyncActionType('PUT_PROFILE_DETAILS');
export const RESET_PUT_PROFILE_DETAILS = 'RESET_PUT_PROFILE_DETAILS';
export const GET_PROFILE_ATTRIBUTES: AsyncActionType = asyncActionType('GET_PROFILE_ATTRIBUTES');
export const RESET_GET_PROFILE_ATTRIBUTES = 'RESET_GET_PROFILE_ATTRIBUTES';
export const PUT_PROFILE_ATTRIBUTES: AsyncActionType = asyncActionType('PUT_PROFILE_ATTRIBUTES');
export const RESET_PUT_PROFILE_ATTRIBUTES = 'RESET_PUT_PROFILE_ATTRIBUTES';

// assessment info
export const GET_ASSESSMENT_INFO: AsyncActionType = asyncActionType('GET_ASSESSMENT_INFO');
export const RESET_GET_ASSESSMENT_INFO = 'RESET_GET_ASSESSMENT_INFO';
export const PUT_ASSESSMENT_INFO: AsyncActionType = asyncActionType('PUT_ASSESSMENT_INFO');
export const RESET_PUT_ASSESSMENT_INFO = 'RESET_PUT_ASSESSMENT_INFO';

// Commercial
export const GET_INCOME_DATA: AsyncActionType = asyncActionType("GET_INCOME_DATA");
export const GET_INCOME_DATA_BY_SNAPSHOT: AsyncActionType = asyncActionType("GET_INCOME_DATA_BY_SNAPSHOT");
export const GET_INCOME_UNIT_BY_ID: AsyncActionType = asyncActionType("GET_INCOME_UNIT_BY_ID");
export const PUT_INCOME_UNIT_BY_ID: AsyncActionType = asyncActionType("PUT_INCOME_UNIT_BY_ID");
export const RESET_PUT_INCOME_UNIT = 'RESET_PUT_INCOME_UNIT';
export const POST_INCOME_UNIT: AsyncActionType = asyncActionType("POST_INCOME_UNIT");
export const RESET_POST_INCOME_UNIT = 'RESET_POST_INCOME_UNIT';
export const RESET_GET_INCOME_BY_ID = 'RESET_GET_INCOME_BY_ID';
export const DELETE_INCOME_UNIT_BY_ID: AsyncActionType = asyncActionType("DELETE_INCOME_UNIT_BY_ID");
export const DELETE_MULTIPLE_INCOME_UNITS: AsyncActionType = asyncActionType("DELETE_MULTIPLE_INCOME_UNITS");
export const MANAGE_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID: AsyncActionType = asyncActionType("MANAGE_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID");
export const RESET_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID = 'RESET_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID';
export const PUT_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID: AsyncActionType = asyncActionType("PUT_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID");
export const RESET_PUT_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID = 'RESET_PUT_INCOME_ATTRIBUTES_BY_SNAPSHOT_ID';
export const RESET_DELETE_INCOME_BY_ID = 'RESET_DELETE_INCOME_BY_ID';
export const GET_TENANT_SUMMARY_BY_SNAPSHOT_ID: AsyncActionType = asyncActionType("GET_TENANT_SUMMARY_BY_SNAPSHOT_ID");
export const GET_APARTMENT_TENANT_SUMMARY_BY_SNAPSHOT_ID: AsyncActionType = asyncActionType("GET_APARTMENT_TENANT_SUMMARY_BY_SNAPSHOT_ID");
export const RESET_GET_TENANT_SUMMARY_BY_SNAPSHOT_ID = "RESET_GET_TENANT_SUMMARY_BY_SNAPSHOT_ID";
export const GET_INDEPENDENT_LIVING_UNITS_BY_SNAPSHOT_ID: AsyncActionType = asyncActionType("GET_INDEPENDENT_LIVING_UNITS_BY_SNAPSHOT_ID");
export const GET_MEMORY_CARE_UNITS_BY_SNAPSHOT_ID: AsyncActionType = asyncActionType("GET_MEMORY_CARE_UNITS_BY_SNAPSHOT_ID");
export const GET_ASSISTED_LIVING_UNITS_BY_SNAPSHOT_ID: AsyncActionType = asyncActionType("GET_ASSISTED_LIVING_UNITS_BY_SNAPSHOT_ID");
export const GET_PREVIOUS_TENANT_SUMMARY_BY_SNAPSHOT_ID: AsyncActionType = asyncActionType("GET_PREVIOUS_TENANT_SUMMARY_BY_SNAPSHOT_ID");
export const RESET_GET_PREVIOUS_TENANT_SUMMARY_BY_SNAPSHOT_ID = "RESET_GET_PREVIOUS_TENANT_SUMMARY_BY_SNAPSHOT_ID";

// document
export const GET_FILE: AsyncActionType = asyncActionType('GET_FILE');
export const RESET_FILE = 'RESET_FILE';

// Comment
export const GET_COMMENT: AsyncActionType = asyncActionType('GET_COMMENT');
export const UPDATE_COMMENT: AsyncActionType = asyncActionType('UPDATE_COMMENT');
export const RESET_COMMENT = 'RESET_COMMENT';
export const RESET_UPDATE_COMMENT = 'RESET_UPDATE_COMMENT';

// Help Centre
export const GET_HELP_FILE: AsyncActionType = asyncActionType('GET_HELP_FILE');
export const RESET_HELP_FILE = 'RESET_HELP_FILE';

// Global Loading
export const INCREMENT_LOADING = 'INCREMENT_LOADING';
export const DECREMENT_LOADING = 'DECREMENT_LOADING';

// Snapshot
export const GET_SNAPSHOT: AsyncActionType = asyncActionType('GET_SNAPSHOT');
export const PUT_SNAPSHOT: AsyncActionType = asyncActionType('PUT_SNAPSHOT');
export const RESET_GET_SNAPSHOT: AsyncActionType = asyncActionType('RESET_GET_SNAPSHOT');
export const RESET_PUT_SNAPSHOT: AsyncActionType = asyncActionType('RESET_PUT_SNAPSHOT');
export const UPDATE_SNAPSHOT = 'UPDATE_SNAPSHOT';