import React from 'react';
import ReactDOM from 'react-dom/client';
import store, { persistedStore } from './redux/store';
import './i18n';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.css';

import "@fontsource/open-sans";
import "@fontsource/quicksand";

import App from './App';

// CSP Policy for FA icons
import { config as faConfig } from '@fortawesome/fontawesome-svg-core';
import "../node_modules/@fortawesome/fontawesome-svg-core/styles.css";
import { ToastContainer } from 'react-toastify';
import LoadingSpinner from './components/common/LoadingSpinner/LoadingSpinner';
import { FormErrorProvider } from './utils/context/FormErrorContext';

faConfig.autoAddCss = false;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <FormErrorProvider>
      <PersistGate persistor={persistedStore} loading={<LoadingSpinner loading={true}/>}>
        <React.StrictMode>
            <App />
            <ToastContainer />
        </React.StrictMode>
      </PersistGate>
    </FormErrorProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
