import IncomeAttributeLookupObject from "../types/lookups/IncomeAttributeLookupObject"

export default function calculateIncomeAttributesTotal(lookups: IncomeAttributeLookupObject[], incomeAttributes: Record<string, unknown>[]) {
  let total: number | undefined = undefined
  if (!lookups || !incomeAttributes || !lookups.length || !incomeAttributes.length) {
    return total
  }

  lookups.forEach((lookup) => {
    const incomeAttribute = incomeAttributes.find(({ code }) => code === lookup.incomeAttributeCode)
    if (incomeAttribute && incomeAttribute["value"]) {
      if (total === undefined) {
        total = 0
      }
      total = total + (incomeAttribute["value"] as number);
    }
  })
  return total
}