import React from "react";
import './Checkbox.css'
import { useReadOnly } from "../../../utils/ReadOnlyContext";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CheckboxProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    checkboxclassname?: string
}

export default function Checkbox(props: CheckboxProps): JSX.Element {
    const { isReadOnly } = useReadOnly()
    return (
        <>
            <label className="checkbox-container" aria-label={props.name ? props.name : props.id}>
                <input type="checkbox" {...props}
                    aria-label={props.name ? props.name : props.id}
                    disabled={isReadOnly || props.disabled} />
                <span className={`checkmark ${props.checkboxclassname}`}></span>
            </label>
        </>
    )
}
