/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 * Update: 18/04/2024 - Mariam Bawa - Refactor code to use IncomeAttributeTableFragmentGenerator to generate table content
 */
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getIncomeAttributesBySnapshotId, putIncomeAttributesBySnapshotId } from "../../redux/actions/incomeAttributes/incomeAttributes";
import { getLookups } from "../../redux/actions/lookups/lookups";
import { RootState } from "../../redux/reducers/root";
import { IncomeAttributeState } from "../../redux/reducers/getIncomeAttributes/getIncomeAttributesReducer";
import { LookupsState } from "../../redux/reducers/getLookups/lookupsReducer";
import { ProfileOverviewState } from "../../redux/reducers/getProfile/getProfileOverviewReducer";
import { SaveDataState } from "../../redux/reducers/getSaveData/saveDataReducer";
import { SaveResultState } from "../../redux/reducers/getSaveData/saveResultReducer";
import IncomeAttributeLookupObject from "../../types/lookups/IncomeAttributeLookupObject";
import calculateIncomeAttributesTotal from "../../utils/calculateIncomeAttributesTotal";
import formatCurrency from "../../utils/formatCurrency";
import IncomeAttributeTableFragmentGenerator from "../../utils/TableGenerator/IncomeAttributeTableFragmentGenerator";
import "./PIE.css";

function CapitalCostsSummary(): ReactElement {
    const { i18n } = useTranslation();
    const { t } = useTranslation(['pie']);
    const dispatch = useDispatch();
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const incomeAttributesState: IncomeAttributeState = useSelector((state: RootState) => state.incomeAttributesReducer.incomeAttributes);
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const lookupState: LookupsState = useSelector((state: RootState) => state.lookupsReducer.getLookups as LookupsState);
    const lookupType = profileOverview.profile.profile_type_code.toLowerCase() + "-income-attributes";
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
    const [total, setTotal] = useState<number | undefined>(undefined)
    const currentSnapshotId = Number(localStorage.getItem('currentSnapshotId') as string)

    useEffect(() => {
        if ((!incomeAttributesState.loading && !incomeAttributesState.success)
            || incomeAttributesState.snapshotId !== currentSnapshotId
        ) {
            dispatch(getIncomeAttributesBySnapshotId(currentSnapshotId));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!incomeAttributesState.loading && incomeAttributesState.success
            && !lookupState.loading && lookupState.success
            && lookupState.lookups[3] && lookupState.lookups[3]["cost"]) {
            setTotal(calculateIncomeAttributesTotal(lookupState.lookups[3]["cost"] as IncomeAttributeLookupObject[],
                incomeAttributesState.data as Record<string, unknown>[]))
        }
    }, [incomeAttributesState, lookupState])

    useEffect(() => {
        if (saveData.saving) {
            const incomeAttributes = (incomeAttributesState.data as Record<string, unknown>[]).filter((data) => data.value || data.description || data.percent);
            dispatch(putIncomeAttributesBySnapshotId(currentSnapshotId, incomeAttributes));
        }
    }, [currentSnapshotId, dispatch, incomeAttributesState.data, saveData.saving])

    useEffect(() => {
        if (saveResultState.status === 200) {
            dispatch(getIncomeAttributesBySnapshotId(currentSnapshotId));
        }
    }, [currentSnapshotId, dispatch, saveResultState]);

    useEffect(() => {
        if (!lookupState.success) {
            dispatch(getLookups(lookupType));
        }
    }, [dispatch, lookupState.success, lookupType]);

    const findAttributeData = (attributeCode: string) => {
        let incomeAttributesData: Record<string, unknown> | undefined = undefined;
        if (incomeAttributesState.data) {
            incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attributeCode);
        }
        return incomeAttributesData;
    };

    const handleCurrencyChange = (newAmt: number | undefined | null, name: string, _oldAmt: number | undefined | null) => {
        const idx = name.lastIndexOf("-");
        if (idx !== -1) {
            const code = name.substring(idx + 1);
            const incomeAttributesData = findAttributeData(code);
            const na = newAmt === undefined ? null : newAmt;
            if (incomeAttributesData) {
                incomeAttributesData["value"] = na;
            } else {
                (incomeAttributesState.data as Record<string, unknown>[]).push({
                    snapshotId: currentSnapshotId,
                    code: code,
                    description: null,
                    value: na,
                    type: "3",
                    percent: null,
                    year: null,
                });
            }

            setTotal(calculateIncomeAttributesTotal(lookupState.lookups[3]["cost"] as IncomeAttributeLookupObject[],
                incomeAttributesState.data as Record<string, unknown>[]))
        }
    }

    const handleCommentChange = (value: string, name: string) => {
        const idx = name.lastIndexOf("-");
        if (idx !== -1) {
            const code = name.substring(idx + 1);
            const incomeAttributesData = findAttributeData(code);
            const v = value.trim() === "" ? null : value.trim();
            if (incomeAttributesData) {
                incomeAttributesData["description"] = v;
            } else {
                (incomeAttributesState.data as Record<string, unknown>[]).push({
                    snapshotId: currentSnapshotId,
                    code: code,
                    description: v,
                    value: null,
                    type: "3",
                    percent: null,
                    year: null
                });
            }
        }
    }

    return (
        <>
            {
                (!incomeAttributesState.loading && !lookupState.loading) &&
                <>
                    {profileOverview.profile.profile_type_code === "MTL" && <p>{t('costs.motelCapitalCostNote')} </p>}
                    <table className="table-with-border pie-table">
                        <tbody>
                            {lookupState.lookups[3]["cost"] && ((lookupState.lookups[3]["cost"] as IncomeAttributeLookupObject[]).map((attr, key) => {
                                let incomeAttributesData: Record<string, unknown> | undefined = undefined;
                                if (incomeAttributesState.data && (incomeAttributesState.data as Record<string, unknown>[]).length > 0) {
                                    incomeAttributesData = (incomeAttributesState.data as Record<string, unknown>[]).find(({ code }) => code === attr.incomeAttributeCode);
                                }
                                if (attr.incomeAttributeCode === '120') {
                                    return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                                        data={incomeAttributesData}
                                        showSubheading={true} hasInputLabel={true} isOtherLabel={false}
                                        handleCommentChange={handleCommentChange}
                                        valueChangeHandler={handleCurrencyChange}
                                        isRequiredField={true}
                                        isCapitalCostsOrReplacementReserve={true} />
                                } else if (attr.incomeAttributeCode === '129' || attr.incomeAttributeCode === '133') {
                                    return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                                        data={incomeAttributesData}
                                        showSubheading={false} hasInputLabel={true} isOtherLabel={false}
                                        handleCommentChange={handleCommentChange}
                                        valueChangeHandler={handleCurrencyChange}
                                        isRequiredField={false}
                                        isCapitalCostsOrReplacementReserve={true} />
                                } else {
                                    return <IncomeAttributeTableFragmentGenerator key={key} attribute={attr}
                                        data={incomeAttributesData}
                                        showSubheading={false} hasInputLabel={false} isOtherLabel={false}
                                        handleCommentChange={handleCommentChange}
                                        valueChangeHandler={handleCurrencyChange}
                                        isRequiredField={false}
                                        isCapitalCostsOrReplacementReserve={true} />
                                }
                            }))}
                        </tbody>
                    </table>
                    <div className='tab-container-footer'>
                        <div className="total-income">
                            <p>{t("costs.totalCapitalExpSummary")}</p>
                            <p>$ {formatCurrency(total, i18n.language)}</p>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
export default CapitalCostsSummary