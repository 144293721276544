import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { BusinessOperationsData } from "../../types/gev/BusinessOperationsData";
import React from "react";

// Context props
interface BusinessOperationsContextProps {
  formData: BusinessOperationsData,
  setFormData: Dispatch<SetStateAction<BusinessOperationsData>>;
}

const BusinessOperationsContext = createContext<BusinessOperationsContextProps | undefined>(undefined);

// Custom hook
export const useBusinessOperationsState = () => {
  const context = useContext(BusinessOperationsContext);
  if (!context) {
    throw new Error('useBusinessOperationsState must be used within a BusinessOperationsProvider');
  }
  return context;
}

// Provider props
interface BusinessOperationsProviderProps {
  children: ReactNode;
}

// Provider
export const BusinessOperationsProvider = (props: BusinessOperationsProviderProps) => {
  // Initial state for the form data
  const [formData, setFormData] = useState<BusinessOperationsData>({
    commercialGrainElevatorFacility: {
      selected: false
    },
    feedMillingProcessingFacility: {
      selected: false
    },
    otherAgriBusiness: {
      selected: false
    },
    farmingOperationCommercialGrain: {
      selected: false
    },
    noFormSelected: {
      selected: false
    }
  });
  
  return (
    <BusinessOperationsContext.Provider value={{ formData, setFormData }}>
      {props.children}
    </BusinessOperationsContext.Provider>
  )
}