import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useReadOnly } from '../../utils/ReadOnlyContext';
import "./GEV.css";
import { OperationDataClass, OperationFieldProps } from '../../types/gev/BusinessOperationFormProps';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { BusinessOperationsKeys } from '../../pages/BusinessOperations/BusinessOperations';

export default function GEVInput(props: OperationFieldProps): JSX.Element {
    const { operation, name } = props;
    const { t } = useTranslation('gev')
    const { isReadOnly } = useReadOnly()
    const { formData, setFormData } = useBusinessOperationsState();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [operation]: {
            ...prevData[operation as BusinessOperationsKeys],
            [name]: value,
            },
        }));
    };

    const currentValue = formData[operation as BusinessOperationsKeys]?.[name as keyof OperationDataClass] ?? '';
    return (
        <>
            <tr className='gev-input-tr'>
                <td className='index'>
                    {props.index && props.index + "."}
                </td>
                <td>
                    <div className='gev-input-label'>
                        {t(operation + "." + name)}
                    </div>
                    <input type={props.type === "number" ? "number" : "text"}
                        id={operation + "." + name}
                        name={operation + "." + name}
                        value={currentValue as unknown as string}
                        className={'gev-input-100' + (props.hasErrors ? ' form-error' : '')}
                        title={t(operation + "." + name)}
                        aria-label={t(operation + "." + name)}
                        autoComplete="off"
                        disabled={isReadOnly}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                            if (props.type === "number") {
                                ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                            }
                        }}
                        onWheel={(e) => {
                            if (props.type === "number") {
                                e.currentTarget.blur()
                            }
                        }}
                    />
                    <div className="input-error-message">
                        {props.errors && props.errors[name] as string}
                    </div>
                </td>
            </tr>
        </>
    )
}
