import { API_REQUEST, GET_FULL_SNAPSHOT_DATA } from "../../actionTypes/actionsTypes";
import { AppAction } from "../../types";

export const getFullSnapshotData = (snapshotId: string | number): AppAction => ({
    type: API_REQUEST,
    payload: {
        url: `/income/get-full-snapshot-data/${snapshotId}`,
        next: GET_FULL_SNAPSHOT_DATA,
        method: 'GET',
        authorization: true
    }
})