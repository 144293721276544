import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from '../common/DatePicker/DatePicker';
import GEVTexttarea from './GEVTexttarea';
import { OperationProps } from '../../types/gev/BusinessOperationFormProps';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { BusinessOperationsKeys } from '../../pages/BusinessOperations/BusinessOperations';
import { NoFormSelectedClass } from '../../types/gev/BusinessOperationsData';
import { FormError, useFormErrorContext } from '../../utils/context/FormErrorContext';
import ErrorMessages from '../common/ErrorMessages/ErrorMessages';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function isNoFormSelected(dataset: any): dataset is NoFormSelectedClass {
    return (
        dataset &&
        typeof dataset.selected === 'boolean' &&
        'dateOfChange' in dataset
    );
}

export default function NoFormSelected(props: OperationProps): JSX.Element {
    const { t } = useTranslation('gev');
    const { formData, setFormData } = useBusinessOperationsState();

    // useEffect(() => {
    //     if (props.validating) {
    //         const err: Record<string, string> = {}
    //         if (!data["changesToProperty"]) {
    //             err["changesToProperty"] = t("missingRequiredField")
    //         }
    //         if (data["changesToProperty"] && data["changesToProperty"].toString().length > 200) {
    //             err["changesToProperty"] = t("max-200-chars")
    //         }
    //         if (!data["dateOfChange"]) {
    //             err["dateOfChange"] = t("missingRequiredField")
    //         }
    //         props.validate(operation)
    //         props.setData(operation, data)
    //         if (Object.keys(err).length) {
    //             props.setErrors(operation, err)
    //         }
    //     }
    // }, [data, props, t])
    const handleDateChange = (date: Date | null | undefined, name: string) => {
        const parsedDate = date ? date.toISOString().substring(0, 10) : null;

        setFormData((prevData) => {
            const dataset = prevData[operation as BusinessOperationsKeys];
            if (isNoFormSelected(dataset)) {
                dataset.dateOfChange = parsedDate
            }

            return {
                ...prevData,
                [operation]: { ...dataset }
            }
        });
    }

    const currentValue = isNoFormSelected(formData) ? formData.dateOfChange : null;
    
    const { errors } = useFormErrorContext();
    const [formErrors, setFormErrors] = useState<FormError[]>([]);
    const { operation, incrementErrCount } = props
    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((error) => {
                if (error.primaryKey && error.primaryKey.split('.')[1] === operation) {
                    // Save only (operation+formKey) combination in error.primaryKey
                    // For grouped elements (eg, checkbox group), the parent div gets the name name as primaryKey
                    const splitKey = error.primaryKey.split('.');
                    error.primaryKey = `${splitKey[1]}.${splitKey[2]}`
                    console.log(error.primaryKey)
                    setFormErrors(prevData => [...prevData, error])
                    incrementErrCount(operation as BusinessOperationsKeys)
                }
            })
        }
        else {
            setFormErrors([]);
        }
    }, [errors, operation, incrementErrCount]);
    return (
        <div>
            <ErrorMessages errors={formErrors} />
            <form>
                <table width={'100%'} role="none" className='gev-table'>
                    <tbody>
                        <GEVTexttarea
                            index={1}
                            operation={operation}
                            name="changesToProperty"
                            errors={props.errors}

                        />
                        <tr className='gev-input-tr'>
                            <td>
                                2. &nbsp;&nbsp;{t(operation + ".dateOfChange")}
                                <DatePicker id="dateOfChange" name="dateOfChange"
                                    date={currentValue}
                                    position="right"
                                    title={t(operation + ".dateOfChange")}
                                    errorMessage={props.errors && props.errors["dateOfChange"] as string}
                                    onChange={handleDateChange} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}
