import { AssessmentInfoState } from "../../../types/gev/AssessmentInfoState"
import { GET_ASSESSMENT_INFO, RESET_GET_ASSESSMENT_INFO } from "../../actionTypes/actionsTypes"
import defaultAsyncReducer from "../../defaultReducer"
import { AppAction, LocalAction } from "../../types"

const initialState: AssessmentInfoState = {
  loading: false,
  success: false,
  error: false,
  message: "",
  formData: {
    commercialGrainElevatorFacility: { selected: false },
    feedMillingProcessingFacility: { selected: false },
    otherAgriBusiness: { selected: false },
    farmingOperationCommercialGrain: { selected: false },
    noFormSelected: { selected: false }
  },
}

function getAssessmentInfoReducer(state = initialState, action: AppAction | LocalAction) {
  if (action.type === RESET_GET_ASSESSMENT_INFO) {
    return initialState
  } else {
    return defaultAsyncReducer(state, action as AppAction, GET_ASSESSMENT_INFO)
  }
}

export default getAssessmentInfoReducer