import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { faRoute, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EXTERNAL_SUBMITTED, RESET_PROPERTIES, RESET_UPDATE_CAMPAIGN_STATUS } from '../redux/actionTypes/actionsTypes';
import { updateAcknowledgementFlag } from '../redux/actions/campaign/updateAcknowledgement';
import { updateCampaignStatus } from '../redux/actions/campaign/updateStatus';
import { getIncomeFile } from '../redux/actions/file/file';
import { getProfileDetails } from '../redux/actions/profile/profile';
import { setSaveResultSuccess } from '../redux/actions/saveData/saveData';
import { RootState } from '../redux/reducers/root';
import { UpdateCampaignState } from '../redux/reducers/getCampaign/updateCampaignStatusReducer';
import { FileState } from '../redux/reducers/getFile/getFileReducer';
import { ProfileDetailsState } from '../redux/reducers/getProfile/getProfileDetailsReducer';
import { SaveDataState } from '../redux/reducers/getSaveData/saveDataReducer';
import { UpdateAcknowledgementState } from '../redux/reducers/updateAcknowledgement/updateAcknowledgementReducer';
import { GeneratePDFPayload } from '../types/pdf/GeneratePDFPayload';
import Button from '../components/common/Button/Button';
import LoadingSpinner from '../components/common/LoadingSpinner/LoadingSpinner';
import '../pages/Summary/Summary.css';

export default function Submit(): JSX.Element {
    const { t } = useTranslation(['common', 'transition']);
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const updateCampaignStatusState: UpdateCampaignState = useSelector((state: RootState) => state.campaignReducer.updateCampaignStatus);
    const acknowledgementState: UpdateAcknowledgementState = useSelector((state: RootState) => state.acknowledgementReducer.updateAcknowledgementFlag);
    const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
    const submitDispatched = useRef(false);
    const [downloading, setDownloading] = useState(false);
    const [isCertified, setIsCertified] = useState(profileDetails.data.acknowledgementFlag || false);
    const rollNumber = localStorage.getItem('rollNumber');
    const fileState: FileState = useSelector((state: RootState) => state.fileReducer.file);
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);

    useEffect(() => {
        if (saveData.saving) {
            dispatch(setSaveResultSuccess());
        }
    }, [dispatch, saveData.saving])

    const submit = () => {
        if (!isCertified) {
            toast.error('Please review and select the certification box before submitting your return.');
            return;
        }

        if (!submitDispatched.current) {
            dispatch({ type: RESET_UPDATE_CAMPAIGN_STATUS });
            dispatch(updateCampaignStatus(EXTERNAL_SUBMITTED,
                localStorage.getItem('profileId') as string,
                localStorage.getItem('campaignId') as string));
            submitDispatched.current = true;
        }
    };

    useEffect(() => {
        if (submitDispatched.current && !updateCampaignStatusState.loading) {
            submitDispatched.current = false;
            if (!updateCampaignStatusState.updated) {
                if (updateCampaignStatusState.message) {
                    toast.error(updateCampaignStatusState.message)
                }
            }
        }
    }, [updateCampaignStatusState]);

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setIsCertified(isChecked);
        dispatch(updateAcknowledgementFlag(
            isChecked,
            localStorage.getItem('currentSnapshotId') as string
        ));
    };

    useEffect(() => {
        if (acknowledgementState.success === true) {
            dispatch(getProfileDetails(localStorage.getItem('currentSnapshotId') as string));
        }
    }, [acknowledgementState.success, dispatch]);

    const downloadPreviewFile = () => {
        const payload: GeneratePDFPayload = {
            language: i18n.language,
            rollNumber: rollNumber as string,
            profileId: Number(localStorage.getItem('profileId') as string),
            campaignId: Number(localStorage.getItem('campaignId') as string),
            campaignYear: Number(localStorage.getItem("activeCampaignYear") as string),
            snapshotRequestType: "2"
        }
        console.log("download preview file payload", payload);
        dispatch(getIncomeFile(payload));
        setDownloading(true);
    };

    useEffect(() => {
        if (downloading && fileState.loading === false) {
            setDownloading(false);
            if (fileState.status === 200) {
                const base64string = "data:application/pdf;base64," + fileState.pdf;
                const downloadLink = document.createElement("a");
                downloadLink.href = base64string;
                downloadLink.download = rollNumber + "_" + localStorage.getItem("activeCampaignYear") as string + "_PIER.pdf";
                downloadLink.click();
            } else {
                toast.error(fileState.message);
            }
        }
    }, [downloading, fileState, rollNumber]);

    const toHome = () => {
        dispatch({ type: RESET_UPDATE_CAMPAIGN_STATUS })
        dispatch({ type: RESET_PROPERTIES })
        if (localStorage.getItem("isInternalUser")) {
            navigate("/internal-landing")
        } else {
            navigate('/landing')
        }
    };

    return (
        <div className='summary-page-container'>
            {
                <>
                    <FontAwesomeIcon icon={faRoute} className='print-icon' />
                    <p className='print-wip-text'>{t('submit-page')}</p>
                    <div className="certify">
                        <input
                            type="checkbox"
                            id="certify-checkbox"
                            checked={isCertified}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="certify-checkbox">
                            {t('certify')}
                        </label>
                    </div>
                    <Button type='submit' text={t('submit')} onClick={() => submit()} />
                </>
            }
            {
                submitDispatched.current && updateCampaignStatusState.updated &&
                <div className="modal-bg">
                    <LoadingSpinner loading={fileState.loading}>
                        <div className="modal-container">
                            <div className="modal-header">
                            </div>
                            <div className="modal-body">
                                <div className='flex flex-column align-items-center'>
                                    <p><FontAwesomeIcon icon={faCircleCheck} color='green' size='3x' /></p>
                                    <p><label>Submission successful</label></p>
                                    <span>Please keep reference number <b>{updateCampaignStatusState.campaign.campaignId as number}</b> for your record.</span>
                                </div>
                                <p></p>
                                <div className='button-group justify-content-center'>
                                    <Button type='submit' text={t('overview.download', { ns: 'transition' })} onClick={() => downloadPreviewFile()} />
                                    <Button type='secondary' text={t("return", { ns: 'transition' })} onClick={() => toHome()} />
                                </div>
                                <p></p>
                            </div>
                        </div>
                    </LoadingSpinner>
                </div>
            }
        </div>
    );
}
