import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '../common/Checkbox/Checkbox';
import "./GEV.css";
import { GrainLicenseOperations, OperationFieldProps } from '../../types/gev/BusinessOperationFormProps';
import { GrainLicenseType } from '../../types/gev/BusinessOperationsData';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { BusinessOperationsKeys } from '../../pages/BusinessOperations/BusinessOperations';

/* eslint-disable  @typescript-eslint/no-explicit-any */
function isGrainLicenseOperations(dataset: any): dataset is GrainLicenseOperations {
    return (
        dataset &&
        typeof dataset.selected === 'boolean' &&
        'grainLicenseType' in dataset
    );
}
export default function GEVGrainLicenseType(props: OperationFieldProps): JSX.Element {
    const { t } = useTranslation('gev');
    const { formData, setFormData } = useBusinessOperationsState();
    const [licenseType, setLicenseType] = useState<GrainLicenseType | undefined>(isGrainLicenseOperations(formData) ? formData.grainLicenseType : {
        elevatorOperator: null,
        grainDealer: null,
        both: null,
        other: null,
    });

    const updateGrainLicenseType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const activeKey = e.currentTarget.name as keyof GrainLicenseType;
        const isChecked = e.currentTarget.checked;

        setLicenseType(() => {
            const updatedData: GrainLicenseType = {
                elevatorOperator: null,
                grainDealer: null,
                both: null,
                other: null,
            };
        
            // If the checkbox is checked, set it to true, otherwise leave it as null
            if (isChecked) {
                updatedData[activeKey] = true;
            }

            return updatedData;
        }); 
    }

    useEffect(() => {
        setFormData((prevData) => {
            const dataset = prevData[props.operation as BusinessOperationsKeys];
            if (isGrainLicenseOperations(dataset)) {
                dataset.grainLicenseType = licenseType;
            }
            return {
                ...prevData,
                [props.operation]: { ...dataset }
            }
        });
    }, [licenseType, props.operation, setFormData]);
    
    return (
        <>
            <tr className='gev-input-tr'>
                <td className='index'>
                    {props.index && props.index + "."}
                </td>
                <td>
                    <div className='gev-input-label'>
                        {t(props.operation + "." + props.name)}
                    </div>
                    <fieldset className='noborder-fieldset'>
                        <legend className='invisible-legend'><span>{t(props.operation + "." + props.name)}</span></legend>
                        <div className='gev-space-between'>
                            {
                                licenseType && Object.entries(licenseType).map(([key, optionData]) => {
                                    return (
                                        <div key={key} className='flex flex-row align-items-center'
                                            title={t(props.operation + "." + props.name + "Options." + key)}
                                            id={`${props.operation}.${props.name}`}>
                                            {t(props.operation + "." + props.name + "Options." + key)}&nbsp;&nbsp;
                                            <Checkbox name={key}
                                                value={optionData ?? undefined}
                                                checked={optionData === true}
                                                onChange={updateGrainLicenseType}
                                                checkboxclassname={props.hasErrors ? 'form-error' : 'checkmark'}
                                            />
                                        </div>
                                    )
                                }
                                )
                            }
                        </div>
                    </fieldset>
                    <div className="input-error-message">
                        {props.errors && props.errors[props.name] as string}
                    </div>
                </td>
            </tr>
        </>
    )
}
