import React from "react";
import { FormError } from "../../../utils/context/FormErrorContext"
import './ErrorMessages.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
interface ErrorMessagesProps { 
  errors: FormError[]
}
export default function ErrorMessages(props: ErrorMessagesProps) {
  const { t } = useTranslation(['common', 'transition']);
  const { i18n } = useTranslation();
  const { errors } = props;
  return (<>
    {errors.length > 0 &&
      <div className="error-container">
          <p className="error-heading">{t('error-msg-header')}</p>
          <ul className="error-list">
            {errors.map((error, idx) => (
              <li key={`${error.primaryKey}-${idx}`}>
                <FontAwesomeIcon icon={faCircleExclamation} className="error-icon" />
                <span onClick={() => {
                  const findByName = document.getElementsByName(error.primaryKey)[0];
                  const findById = document.getElementById(error.primaryKey);
                  if (findByName) {
                    findByName.scrollIntoView({ behavior: 'smooth' });
                  }
                  else if (findById) {
                    findById.scrollIntoView({ behavior: 'smooth'})
                  }
                }}>{i18n.language === 'fr' ? error.messageFr : error.message}</span>
              </li>
            ))}

          </ul>
      </div>
    }
  </>)
}