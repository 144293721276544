/**
 * Update: 15/04/2024 - Mariam Bawa - update import statements as part of data type refactoring 
 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../../components/common/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faPenToSquare, faPlus, faTrashCan, faSortAsc, faSortDesc, faXmark, faFilterCircleXmark, faWandMagicSparkles } from "@fortawesome/free-solid-svg-icons";
import Asterisk from "../../../components/common/Asterisk/Asterisk";
import DataTable from "../../../components/common/DataTable/DataTable";
import Modal from "../../../components/common/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  getTenantSummaryBySnapshotId,
  getIncomeUnitById,
  resetGetIncomeUnitById,
  deleteIncomeUnitById,
  resetDeleteIncomeUnitById,
  resetGetTenantSummaryBySnapshotId,
  putIncomeUnit,
  resetPutIncomeUnit,
  resetPostIncomeUnit,
  postIncomeUnit,
  deleteMultipleIncomeUnits
} from "../../../redux/actions/incomeUnit/incomeUnit";
import { RootState } from "../../../redux/reducers/root";
import LoadingSpinner from "../../../components/common/LoadingSpinner/LoadingSpinner";
import { ToastContainer, ToastContentProps, ToastOptions, TypeOptions, toast } from "react-toastify";
import { GetIncomeUnitState } from "../../../redux/reducers/getIncomeUnit/getIncomeUnitReducer";
import { BaseAsyncReducerState } from "../../../redux/types";
import { GetTenantSummaryState } from "../../../redux/reducers/getIncomeUnit/getTenantSummaryReducer";
import { GetElsLookupsState } from "../../../redux/reducers/getLookups/getElsLookupsReducer";
import Dropdown from "../../../components/common/Dropdown/Dropdown";
import Radio from "../../../components/common/Radio/Radio";
import './LeaseInfo.css';
import './Dropdown.css';
import ToolTip from "../../../components/common/ToolTip/ToolTip";
import CommercialUnitType from "../../../types/unit/CommercialUnit";
import CommercialUnit from "../../../components/unit/CommercialUnit";
import { SaveDataState } from "../../../redux/reducers/getSaveData/saveDataReducer";
import { updateUnitState } from "../../../redux/reducers/getIncomeUnit/updateUnitReducer";
import { createUnitState } from "../../../redux/reducers/getIncomeUnit/createUnitReducer";
import { format, parseISO } from 'date-fns';
import 'react-toastify/dist/ReactToastify.css';
import GuidedExperienceWizard from "../../../components/GuidedExperience/GuidedExperienceWizard";
import { useReadOnly } from "../../../utils/ReadOnlyContext";
import RadioButton from "../../../components/common/RadioGroup/RadioButton";
import { ProfileDetailsState } from '../../../redux/reducers/getProfile/getProfileDetailsReducer';
import { ProfileOverviewState } from "../../../redux/reducers/getProfile/getProfileOverviewReducer";
import { SaveResultState } from '../../../redux/reducers/getSaveData/saveResultReducer';
import { resetPutProfileDetails, putProfileDetails, getProfileDetails } from "../../../redux/actions/profile/profile";

interface ToastMessage {
  type: string,
  msg: string,
  filterRule: Record<string, unknown>
}

export default function LeaseInfo(): JSX.Element {
  const { isReadOnly } = useReadOnly()
  const { t } = useTranslation(["lease_table", "common"]);
  const { i18n } = useTranslation();
  const [data, setData] = useState<Record<string, string | number>[]>([]);
  const [showAddUnitModal, setShowAddUnitModal] = useState(false);
  const [showEditUnitModal, setShowEditUnitModal] = useState(false);
  const [showWizardModal, setShowWizardModal] = useState(false);
  const [importSnapshotId, setImportSnapshotId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberPerPage, setNumberPerPage] = useState(10);
  const getTenantSummaryDispatched = useRef(false);

  const [filterRules, setFilterRules] = useState([] as Array<Record<string, unknown>>);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [filterMissingMandatoryField, setFilterMissingMandatoryField] = useState(false);
  const [filterMissingMandatoryVacantField, setFilterMissingMandatoryVacantField] = useState(false);
  const [toastFiltering, setToastFiltering] = useState(false);
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const profileDetails: ProfileDetailsState = useSelector((state: RootState) => state.profileReducer.getProfileDetails);
  const showCommercialQuestion = ["340", "341", "352", "361", "470", "472", "478"].includes(localStorage.getItem("propertyCode") as string)
  const [isCommercial, setIsCommercial] = useState<boolean | null>(null)

  const filterMehodOption = {
    "text": [
      { text: "Contains", value: "Contains" },
      { text: "Not Contains", value: "NotContains" },
      { text: "Equals", value: "Equals" },
      { text: "Not Equals", value: "NotEquals" },
      { text: "Is Blank", value: "IsBlank", filterValue: false },
      { text: "Is Not Blank", value: "IsNotBlank", filterValue: false },
    ],
    "date": [
      { text: "Before", value: "Before" },
      { text: "After", value: "After" },
      { text: "Contains", value: "Contains" },
      { text: "Not Contains", value: "NotContains" },
      { text: "Equals", value: "Equals" },
      { text: "Not Equals", value: "NotEquals" },
      { text: "Is Blank", value: "IsBlank", filterValue: false },
      { text: "Is Not Blank", value: "IsNotBlank", filterValue: false },
    ],
    "number": [
      { text: "<", value: "<" },
      { text: ">", value: ">" },
      { text: "=", value: "=" },
      { text: "!=", value: "NotEqualTo" },
      { text: "Is Null", value: "IsNull", filterValue: false },
      { text: "Is Not Null", value: "IsNotNull", filterValue: false },
    ]
  };

  const summaryMandatoryFields = [
    "tenantName",
    "tenantUnitNumber",
    "startDate",
    "leaseTypeCode",
    "leaseStatusCode",
    "netLeasableArea"
  ];

  const toastFilterClicked = (toastMsg: ToastMessage) => {
    const filterRule = toastMsg.filterRule;
    setToastFiltering(true);
    if (filterRule["filterColumn"]["column"] === "missingMandatory") {
      setFilterRules([]);
      setFilterMissingMandatoryVacantField(false);
      setFilterMissingMandatoryField(true);
    } else if (filterRule["filterColumn"]["column"] === "missingMandatoryVacant") {
      setFilterRules([]);
      setFilterMissingMandatoryVacantField(true);
      setFilterMissingMandatoryField(false);
    }else {
      setFilterMissingMandatoryVacantField(false);
      setFilterMissingMandatoryField(false);
      setFilterRules([filterRule]);
    }
  };

  const toastClearClicked = () => {
    setToastFiltering(false);
    setFilterRules([]);
    setFilterMissingMandatoryVacantField(false);
    setFilterMissingMandatoryField(false);
  };

  const notifyLeaseInfo = (data: ToastMessage) => {
    if(isReadOnly) {
      return;
    }
    const props: ToastOptions<ToastMessage> = {
      containerId: 'lease-table-toast-container',
      type: data.type as TypeOptions,
      // theme: "colored",
      className: "toast-" + data.type,
      data: data
    };
    toast((props: ToastContentProps<ToastMessage>) => {
      const toastMsg = props.data;
      return <div className="flex flex-row justify-content-between">
        <div>{toastMsg["msg"]}</div>
        <div className="button-group flex-row align-items-center">
          <FontAwesomeIcon icon={faFilter} size="lg" onClick={() => toastFilterClicked(toastMsg)} />
          <FontAwesomeIcon icon={faFilterCircleXmark} size="lg" onClick={() => toastClearClicked()} />
        </div>
      </div>
    }, props);
  };

  const emptyUnit: CommercialUnitType = {
    tenantName: undefined,
    unitSubtypeCode: undefined,
    leaseStatusCode: undefined,
    startDate: undefined,
    negotiatedDate: undefined,
    endDate: undefined,
    landLeaseFlag: undefined,
    leaseTypeCode: undefined,
    netLeasableArea: undefined,
    tenantUnitNumber: undefined,
    floorLevel: undefined,
    includeInsuranceFlag: undefined,
    includeManagementFlag: undefined,
    includeMaintenanceFlag: undefined,
    includeHydroFlag: undefined,
    includeWaterFlag: undefined,
    includeHvacFlag: undefined,
    annualRent: undefined,
    currentBaseRent: undefined,
    propertyTaxRecovery: undefined,
    operatingRecovery: undefined,
    freeRentMonth: undefined,
    tenantImprovement: undefined,
    stepUpDate1: undefined,
    stepUpRate1: undefined,
    stepUpDate2: undefined,
    stepUpRate2: undefined,
    stepUpDate3: undefined,
    stepUpRate3: undefined,
    stepUpDate4: undefined,
    stepUpRate4: undefined,
    buildingIdNumber: undefined,
    tenancyTypeCode: undefined,
    designTypeCode: undefined,
    variables: [],
    analysisFlag: undefined,
    analysisReviewedBy: undefined,
    analysisReviewedDate: undefined,
    priority: undefined,
    linkFlag: undefined,
    snapshotId: undefined,
    tenantId: undefined
  }

  const [currentSortingField, setCurrentSortingField] = useState("");
  const [currentSortingOrder, setCurrentSortingOrder] = useState(2);
  const sortingOrders = [1, -1, 0]; // [asc, desc, no sorting]

  const SortingIndicator = (field) => {
    if (field === currentSortingField) {
      if (currentSortingOrder === 0) {
        return <FontAwesomeIcon icon={faSortAsc} />
      } else if (currentSortingOrder === 1) {
        return <FontAwesomeIcon icon={faSortDesc} />
      }
    }
    return null;
  }


  const [currentUnit, setCurrentUnit] = useState<CommercialUnitType>(emptyUnit);
  const [gettingUnit, setGettingUnit] = useState(false);
  const [currentSnapshotId, setCurrentSnapshotId] = useState(0);
  const [profileData, setProfileData] = useState({ ...profileDetails.data })
  const getTenantSummaryState: GetTenantSummaryState = useSelector((state: RootState) => state.incomeUnitReducer.getTenentSummary);
  const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
  const getIncomeUnitState: GetIncomeUnitState = useSelector((state: RootState) => state.incomeUnitReducer.getIncomeUnit);
  const deleteIncomeUnitState: BaseAsyncReducerState = useSelector((state: RootState) => state.incomeUnitReducer.deleteIncomeUnit);
  const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData);
  const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult)
  const updateUnitState: updateUnitState = useSelector((state: RootState) => state.incomeUnitReducer.putIncomeUnit)
  const createUnitState: createUnitState = useSelector((state: RootState) => state.incomeUnitReducer.postIncomeUnit)

  const isModifiedSubmission = localStorage.getItem("isInternalUser") && localStorage.getItem("editMode");
  const dispatch = useDispatch()

  useEffect(() => {
    if (!updateUnitState.loading && updateUnitState.success) {
      setShowEditUnitModal(false)
      dispatch(resetPutIncomeUnit())
      dispatch(getTenantSummaryBySnapshotId(currentSnapshotId, "1"));
      toast.success(t("messages.unit-updated") as string)
    } else if (!updateUnitState.loading && updateUnitState.error) {
      toast.error(t("messages.unit-update-error") as string)
      dispatch(resetPutIncomeUnit())
    }
  }, [updateUnitState, dispatch, currentSnapshotId, t])


  useEffect(() => {
    if (!createUnitState.loading && createUnitState.success) {
      setShowAddUnitModal(false)
      dispatch(resetPostIncomeUnit())
      dispatch(getTenantSummaryBySnapshotId(currentSnapshotId, "1"))
      toast.success(t("messages.unit-created") as string)
    } else if (!createUnitState.loading && createUnitState.error) {
      toast.error(t("messages.unit-create-error") as string)
      dispatch(resetPostIncomeUnit())
    }
  }, [createUnitState, t, dispatch, currentSnapshotId])


  useEffect(() => {
    if (currentSnapshotId !== Number(localStorage.getItem("currentSnapshotId"))) {
      setCurrentSnapshotId(Number(localStorage.getItem("currentSnapshotId")));
      dispatch(resetGetTenantSummaryBySnapshotId());
    }
  }, [currentSnapshotId, dispatch]);

  useEffect(() => {
    if (currentSnapshotId) {
      if (getTenantSummaryDispatched.current === false && getTenantSummaryState.success === false) {
        // Fetch data only if import was selected in the beginning
        dispatch(getTenantSummaryBySnapshotId(currentSnapshotId, "1"));
        getTenantSummaryDispatched.current = true;
      }
    }
  }, [dispatch, getTenantSummaryState.success, currentSnapshotId]);

  const toastify = () => {
    toast.dismiss();

    const summary = getTenantSummaryState.tenantSummary;

    const area = 10;
    const leaseAreaLess = summary.filter((s) => s["netLeasableArea"] !== null && (s["netLeasableArea"] as number) < area);
    if (leaseAreaLess && leaseAreaLess.length > 0) {
      notifyLeaseInfo({
        msg: <Trans t={t}
          i18nKey="messages.leased-area-lessthan" // optional -> fallbacks to defaults if not provided
          values={{ count: leaseAreaLess.length, area: area, name: columns.find((c) => c["column"] === "netLeasableArea")["text"] }}
          components={{ stong: <strong /> }}
        />,
        type: "warning",
        filterRule: {
          "filterColumn": columns.find((c) => c["column"] === "netLeasableArea"),
          "filterMethod": filterMehodOption["number"].find((m) => m["value"] === "<"),
          "filterText": area.toString()
        }
      });
    }

    const leaseStatusVacent = summary.filter((s) => {
      return s["leaseStatusCode"] === "1" && summaryMandatoryFields.some((field) => {
        return ((s[field] === null || s[field] === "") && field !== "leaseTypeCode" );
      });  
    });
    if (leaseStatusVacent && leaseStatusVacent.length > 0) {
      notifyLeaseInfo({
        msg: <Trans t={t}
          i18nKey="messages.lease-status-vacant" // optional -> fallbacks to defaults if not provided
          values={{ count: leaseStatusVacent.length, name: columns.find((c) => c["column"] === "leaseStatusCode")["text"] }}
          components={{ stong: <strong /> }}
        />,
        type: "warning",
        filterRule: {
          "filterColumn": { column: "missingMandatoryVacant" },
          "filterMethod": null,
          "filterText": ""
        }
      });
    }

    const today = new Date().toISOString().substring(0, 10);
    const endDateExpired = summary.filter((s) => (s["endDate"] as string) < today);
    if (endDateExpired && endDateExpired.length > 0) {
      notifyLeaseInfo({
        msg: <Trans t={t}
          i18nKey="messages.end-date-expired" // optional -> fallbacks to defaults if not provided
          values={{ count: endDateExpired.length, name: columns.find((c) => c["column"] === "endDate")["text"] }}
          components={{ stong: <strong /> }}
        />,
        type: "error",
        filterRule: {
          "filterColumn": columns.find((c) => c["column"] === "endDate"),
          "filterMethod": filterMehodOption["date"].find((m) => m["value"] === "Before"),
          "filterText": today
        }
      });
    }

    const missingMandatory = getTenantSummaryState.tenantSummary.filter((ts) => {
      return ts["leaseStatusCode"] !== "1" && summaryMandatoryFields.some((field) => {
        return (ts[field] === null || ts[field] === "");
      });
    });
    if (missingMandatory && missingMandatory.length > 0) {
      notifyLeaseInfo({
        msg: <Trans t={t}
          i18nKey="messages.field-missing"
          values={{ count: missingMandatory.length }}
          components={{ stong: <strong /> }}
        />,
        type: "error",
        filterRule: {
          "filterColumn": { column: "missingMandatory" },
          "filterMethod": null,
          "filterText": ""
        }
      });
    }

    // const unitNumberMissing = summary.filter((s) => !(s["tenantUnitNumber"] && (s["tenantUnitNumber"] as string).length > 0));
    // if (unitNumberMissing && unitNumberMissing.length > 0) {
    //   notifyLeaseInfo({
    //     msg: <Trans t={t}
    //       i18nKey="messages.unit-number-missing" // optional -> fallbacks to defaults if not provided
    //       values={{ name: columns.find((c) => c["column"] === "tenantUnitNumber")["text"] }}
    //       components={{ stong: <strong /> }}
    //     />,
    //     type: "error",
    //     filterRule: {
    //       "filterColumn": columns.find((c) => c["column"] === "tenantUnitNumber"),
    //       "filterMethod": filterMehodOption["text"].find((m) => m["value"] === "IsBlank"),
    //       "filterText": ""
    //     }
    //   });
    // }
  }

  const getPreviousCampaignSnapshotId = () => {
    if (profileOverview && profileOverview.profile && profileOverview.profile.previous_campaigns
        && profileOverview.profile.previous_campaigns.length > 0
    ) {
        const previous_campaigns = profileOverview.profile.previous_campaigns.filter((c) => c.currentSnapshotId).sort((a, b) => b.year - a.year)
        const latest_campaign = previous_campaigns[0]
        if (!latest_campaign || latest_campaign.year < profileOverview.profile.current_campaign.year - 5) {
            return null
        } else {
            return latest_campaign.currentSnapshotId
        }
    }
    return null
}

  useEffect(() => {
    if (getTenantSummaryDispatched.current && getTenantSummaryState.loading === false) {
      if (getTenantSummaryState.success === true) {
        const summary = getTenantSummaryState.tenantSummary.filter((ts) => ts["visible"] !== false);
        if (!summary || summary.length === 0) {
          setShowWizardModal(true);
          setImportSnapshotId(getPreviousCampaignSnapshotId());
        }
        else {
          setImportSnapshotId(null);
        }
        setData(summary);
        toastify();
      } else if (getTenantSummaryState.status === 400) {
        setShowWizardModal(true);
        setImportSnapshotId(getPreviousCampaignSnapshotId());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTenantSummaryState, i18n.language]);

  useEffect(() => {
    if (getIncomeUnitState.loading === false && gettingUnit === true) {
      if (getIncomeUnitState.error === true) {
        toast.error(t("messages.unit-not-found") as string);
        dispatch(resetGetIncomeUnitById());
      } else if (getIncomeUnitState.success === true) {
        setCurrentUnit(getIncomeUnitState.incomeUnitData);
        setShowEditUnitModal(true);
      }
      setGettingUnit(false);
    }
  }, [dispatch, getIncomeUnitState, gettingUnit, t])

  useEffect(() => {
    if (!deleteIncomeUnitState.loading) {
      if (deleteIncomeUnitState.success !== undefined) {
        if (deleteIncomeUnitState.success) {
          dispatch(getTenantSummaryBySnapshotId(currentSnapshotId, "1"))
          toast.success(t("messages.unit-deleted") as string);
        } else {
          toast.error(t("messages.unit-not-deleted") as string);
        }
        dispatch(resetDeleteIncomeUnitById());
      }
    }
  }, [deleteIncomeUnitState, t, dispatch, currentSnapshotId])

  const getUnit = (e: React.MouseEvent, snapshotId: string, tenantId: string) => {
    e.preventDefault();
    dispatch(resetGetIncomeUnitById());
    dispatch(getIncomeUnitById(snapshotId, tenantId));
    setGettingUnit(true);
  }

  const removeUnit = (e: React.MouseEvent, snapshotId: string, tenantId: string) => {
    e.preventDefault();
    dispatch(deleteIncomeUnitById(snapshotId, tenantId));
  }

  const updateUnit = (updatedUnit: Record<string, unknown>) => {
    dispatch(putIncomeUnit(currentSnapshotId, updatedUnit))
  }

  const createUnit = (createdUnit: Record<string, unknown>) => {
    dispatch(postIncomeUnit(currentSnapshotId, createdUnit))
  }

  const removeCurrentUnit = () => {
    dispatch(deleteIncomeUnitById(currentUnit.snapshotId, currentUnit.tenantId));
  }

  const getLeaseStypeDescription = (leaseTypeCode: string) => {
    let leaseTypeDesc: Record<string, unknown>[] = [];
    if (leaseTypeCode) {
      leaseTypeDesc = (elsLookupsState.dropdowns['unitLeaseType'] as Record<string, unknown>[])
        .filter((leaseType) => leaseType.unitLeaseTypeCode === leaseTypeCode);
    }

    return (leaseTypeDesc && leaseTypeDesc.length > 0) ? leaseTypeDesc[0].unitLeaseTypeDescription : "";
  }

  const getLeaseStatusDescription = (leaseStatusCode: string) => {
    let leaseStatusDesc: Record<string, unknown>[] = [];
    if (leaseStatusCode) {
      leaseStatusDesc = (elsLookupsState.dropdowns["unitLeaseStatus"] as Record<string, unknown>[])
        .filter((leaseStatus) => leaseStatus.unitLeaseStatusCode === leaseStatusCode);
    }

    return (leaseStatusDesc && leaseStatusDesc.length > 0) ? leaseStatusDesc[0].unitLeaseStatusDescription : "";
  }

  const getTenancyTypeDescription = (selectedCode: string) => {
    let ttDesc: Record<string, unknown>[] = [];
    if (selectedCode) {
      ttDesc = (elsLookupsState.dropdowns["tenancyType"] as Record<string, unknown>[])
        .filter((tenancyType) => tenancyType.tenancyTypeCode === selectedCode);
    }

    return (ttDesc && ttDesc.length > 0) ? ttDesc[0].tenancyTypeDescription : "";
  }

  const getDesignTypeDescription = (selectedCode: string) => {
    let dtDesc: Record<string, unknown>[] = [];
    if (selectedCode) {
      dtDesc = (elsLookupsState.dropdowns["designType"] as Record<string, unknown>[])
        .filter((designType) => designType.unitDesignTypeCode === selectedCode);
    }
    return (dtDesc && dtDesc.length > 0) ? dtDesc[0].unitDesignTypeDescription : "";
  }

  const sortByNum = (a, b, field, order) => {
    if (a[field] == null && b[field] == null) {
      return 0;
    } else if (a[field] == null && b[field] != null) {
      return 1;
    } else if (a[field] != null && b[field] == null) {
      return -1;
    }

    const diff = a[field] - b[field];
    if (order === 1) {
      return diff;
    }
    return -1 * diff;
  }

  const sortByText = (a, b, field, order) => {
    if (a[field] == null && b[field] == null) {
      return 0;
    } else if (a[field] == null && b[field] != null) {
      return 1;
    } else if (a[field] != null && b[field] == null) {
      return -1;
    }

    const diff = a[field].localeCompare(b[field]);
    if (order === 1) {
      return diff;
    }
    return -1 * diff;
  }

  const sortByFunc = (a, b, field, order, func) => {
    const valuea = func(a[field]);
    const valueb = func(b[field]);
    if (valuea == null && valueb == null) {
      return 0;
    } else if (valuea == null && valueb != null) {
      return 1;
    } else if (valuea != null && valueb == null) {
      return -1;
    }

    const diff = valuea.localeCompare(valueb);
    if (order === 1) {
      return diff;
    }
    return -1 * diff;
  }

  const sorting = (e) => {
    const field = e.target.id;
    let order = 0;
    if (currentSortingField === field) {
      order = (currentSortingOrder + 1) % 3;
    }
    setCurrentSortingField(field);
    setCurrentSortingOrder(order);

    const visibleData = getTenantSummaryState.tenantSummary.filter((ts) => ts["visible"] !== false);
    if (order === 2) {
      setData(visibleData);
      return;
    }

    let sorted = null;
    if (field === "netLeasableArea") {
      sorted = visibleData.toSorted((a, b) => sortByNum(a, b, field, sortingOrders[order]));
    } else if (field === "leaseTypeCode") {
      sorted = visibleData.toSorted((a, b) => sortByFunc(a, b, field, sortingOrders[order], getLeaseStypeDescription));
      setData(sorted);
    } else if (field === "leaseStatusCode") {
      sorted = visibleData.toSorted((a, b) => sortByFunc(a, b, field, sortingOrders[order], getLeaseStatusDescription));
      setData(sorted);
    } else {
      sorted = visibleData.toSorted((a, b) => sortByText(a, b, field, sortingOrders[order]));
    }
    setData(sorted);
  }

  let headings = [
    <th id="tenantName" key="column-1-header" onClick={(e) => sorting(e)}>
      <ToolTip placement="bottom" contents={<>{t('headings.tenant-name')} <Asterisk /> {SortingIndicator("tenantName")} </>}>
        {t('tooltips.tenant-name-tooltip')}
      </ToolTip>
    </th>,
    <th key="column-2-header" id="tenantUnitNumber" onClick={(e) => sorting(e)}>
      <ToolTip contents={<>{t('headings.unit-number')} <Asterisk /> {SortingIndicator("tenantUnitNumber")} </>}>
        {t('tooltips.unit-number-tooltip')}
      </ToolTip>
    </th>,
    <th key="column-3-header" id="startDate" onClick={(e) => sorting(e)}>
      <ToolTip contents={<>{t('headings.start-date')} <Asterisk /> {SortingIndicator("startDate")} </>}>
        {t('tooltips.start-date-tooltip')}
      </ToolTip>
    </th>,
    <th key="column-4-header" id="endDate" onClick={(e) => sorting(e)} >
      <ToolTip contents={<>{t('headings.end-date')} {SortingIndicator("endDate")} </>}>
        {t('tooltips.end-date-tooltip')}
      </ToolTip>
    </th>,
    <th key="column-5-header" id="leaseTypeCode" onClick={(e) => sorting(e)}>
      <ToolTip contents={<>{t('headings.lease-type')} <Asterisk /> {SortingIndicator("leaseTypeCode")}</>}>
        {t('tooltips.lease-type-tooltip')}
      </ToolTip>
    </th>,
    <th key="column-7-header" id="leaseStatusCode" onClick={(e) => sorting(e)}>
      <ToolTip contents={<>{t('headings.lease-status')} <Asterisk /> {SortingIndicator("leaseStatusCode")}</>}>
        {t('tooltips.lease-status-tooltip')}
      </ToolTip>
    </th>,
    <th key="column-6-header" id="netLeasableArea" onClick={(e) => sorting(e)}>
      <ToolTip contents={<>{t('headings.leased-area')} <Asterisk /> {SortingIndicator("netLeasableArea")}</>}>
        {t('tooltips.leased-area-tooltip')}
      </ToolTip>
    </th>,
    <th key="edit-column"></th>,
    <th key="delete-column"></th>
  ];

  if (isModifiedSubmission) {
    headings = [
      <th id="bin" key="column-13-header">
        BIN
      </th>,
      <th id="tenancyType" key="column-8-header">
        Tenancy Type
      </th>,
      <th id="designType" key="column-9-header">
        Design Type
      </th>,
      <th id="analysisFlag" key="column-10-header">
        Analysis
      </th>,
      <th id="priorityFlag" key="column-11-header">
        Priority
      </th>,
      <th id="linkFlag" key="column-12-header">
        Link
      </th>,
      <th id="tenantName" key="column-1-header" onClick={(e) => sorting(e)}>
        <ToolTip placement="bottom" contents={<>{t('headings.tenant-name')} <Asterisk /> {SortingIndicator("tenantName")} </>}>
          {t('tooltips.tenant-name-tooltip')}
        </ToolTip>
      </th>,
      <th key="column-2-header" id="tenantUnitNumber" onClick={(e) => sorting(e)}>
        <ToolTip contents={<>{t('headings.unit-number')} <Asterisk /> {SortingIndicator("tenantUnitNumber")} </>}>
          {t('tooltips.unit-number-tooltip')}
        </ToolTip>
      </th>,
      <th key="column-3-header" id="startDate" onClick={(e) => sorting(e)}>
        <ToolTip contents={<>{t('headings.start-date')} <Asterisk /> {SortingIndicator("startDate")} </>}>
          {t('tooltips.start-date-tooltip')}
        </ToolTip>
      </th>,
      <th key="column-4-header" id="endDate" onClick={(e) => sorting(e)} >
        <ToolTip contents={<>{t('headings.end-date')} {SortingIndicator("endDate")} </>}>
          {t('tooltips.end-date-tooltip')}
        </ToolTip>
      </th>,
      <th key="column-5-header" id="leaseTypeCode" onClick={(e) => sorting(e)}>
        <ToolTip contents={<>{t('headings.lease-type')} <Asterisk /> {SortingIndicator("leaseTypeCode")}</>}>
          {t('tooltips.lease-type-tooltip')}
        </ToolTip>
      </th>,
      <th key="column-7-header" id="leaseStatusCode" onClick={(e) => sorting(e)}>
        <ToolTip contents={<>{t('headings.lease-status')} <Asterisk /> {SortingIndicator("leaseStatusCode")}</>}>
          {t('tooltips.lease-status-tooltip')}
        </ToolTip>
      </th>,
      <th key="column-6-header" id="netLeasableArea" onClick={(e) => sorting(e)}>
        <ToolTip contents={<>{t('headings.leased-area')} <Asterisk /> {SortingIndicator("netLeasableArea")}</>}>
          {t('tooltips.leased-area-tooltip')}
        </ToolTip>
      </th>,
      <th key="edit-column"></th>,
      <th key="delete-column"></th>
    ];
  }
  const columns = [];
  headings.forEach((heading) => {
    const props = heading.props;
    if (Object.keys(props).length > 0) {
      const column = {};
      for (const [key, value] of Object.entries(props)) {
        if (key === "id") {
          column["column"] = value;
          if (value === "netLeasableArea") {
            column["type"] = "number";
          } else {
            column["type"] = "text";
          }
          if (value === "leaseTypeCode") {
            column["fn"] = getLeaseStypeDescription;
          }
          if (value === "leaseStatusCode") {
            column["fn"] = getLeaseStatusDescription;
          }
        }
        if (key === "children") {
          if (value.props != null || (value.type && value.type.name === "ToolTip")) {
            const valueContents = value.props.contents;
            column["text"] = valueContents.props.children[0];
          } else {
            column["text"] = value;
          }
        }
      }
      columns.push(column);
    }
  });

  const formatOutput = (value) => {
    if (value == null || value === undefined) {
      return ""
    } else {
      return value.toString();
    }
  }

  const buildTableContent = (): JSX.Element => {
    const tableBody: JSX.Element[] = [];
    for (let index = numberPerPage * (currentPage - 1); index < Math.min(data.length, numberPerPage * currentPage); index++) {
      tableBody.push(
        <tr key={`table-row-${index}`}>
          {isModifiedSubmission && <>
            <td>{formatOutput(data[index].bin)}</td>
            <td>{formatOutput(getTenancyTypeDescription(data[index].tenancyTypeCode as string))}</td>
            <td>{formatOutput(getDesignTypeDescription(data[index].designTypeCode as string))}</td>
            <td>{formatOutput(data[index].analysisFlag)}</td>
            <td>{formatOutput(data[index].priority)}</td>
            <td className="link-checkbox"><input type="checkbox" checked={Boolean(data[index].linkFlag)} disabled={true} /></td>
          </>}
          <td className={(formatOutput(data[index].tenantName) === "") ? "highlighted-mandatory-cell" : ""}>{formatOutput(data[index].tenantName)}</td>
          <td className={(formatOutput(data[index].tenantUnitNumber) === "") ? "highlighted-mandatory-cell" : ""}>{formatOutput(data[index].tenantUnitNumber)}</td>
          <td className={(formatOutput(data[index].startDate) === "") ? "highlighted-mandatory-cell" : ""}>{formatOutput(data[index].startDate)}</td>
          <td>{formatOutput(data[index].endDate)}</td>
          <td className={((formatOutput(getLeaseStypeDescription(data[index].leaseTypeCode))) === "" && (data[index].leaseStatusCode !== "1")) ? "highlighted-mandatory-cell" : ""}>{formatOutput(getLeaseStypeDescription(data[index].leaseTypeCode))}</td>
          <td className={(formatOutput(getLeaseStatusDescription(data[index].leaseStatusCode))) === "" ? "highlighted-mandatory-cell" : ""}>{formatOutput(getLeaseStatusDescription(data[index].leaseStatusCode))}</td>
          <td className={(formatOutput(data[index].netLeasableArea) === "") ? "highlighted-mandatory-cell" : ""}>{formatOutput(data[index].netLeasableArea)}</td>
          <td>
            <Button
              forceEnabled={true}
              onClick={(e) => {
                getUnit(e, data[index].snapshotId as string, data[index].tenantId as string)
              }}
              text={<FontAwesomeIcon icon={faPenToSquare} />}
              className="edit-btn" ariaLabel={t("edit") + " " + index} />
          </td>
          <td>
            <Button
              onClick={(e) => removeUnit(e, data[index].snapshotId as string, data[index].tenantId as string)}
              text={<FontAwesomeIcon icon={faTrashCan}
                className="trash" />}
              className="delete-btn"
              ariaLabel={t("remove") + " " + index} />
          </td>
        </tr>)
    }
    return (<>{tableBody}</>);
  }

  const submitCommercial = useCallback(() => {
    dispatch(resetPutProfileDetails());
    dispatch(putProfileDetails(profileOverview.profile.profile_type_code, currentSnapshotId, profileData));
    if (!isCommercial && data?.length) {
      dispatch(deleteMultipleIncomeUnits(currentSnapshotId, data))
    }
  }, [dispatch, profileOverview, currentSnapshotId, profileData, data, isCommercial])

  useEffect(() => {
    if (saveData.saving) {
      submitCommercial();
    }
  }, [saveData.saving, submitCommercial])

  useEffect(() => {
    if (saveResultState.status === 200) {
      dispatch(getProfileDetails(currentSnapshotId))
    }
  }, [saveResultState, profileOverview.profile, dispatch, currentSnapshotId]);

  useEffect(() => {
    if (data.length) {
      setIsCommercial(true)
    } else {
      setIsCommercial(false)
    }
  }, [data])

  const FilterPopover = () => {
    const [filterColumn, setFilterColumn] = useState(columns[0]);
    const excludeSearch = filterRules.filter((rule) => ("text" in rule["filterColumn"]));

    return (
      <div>
        <form onSubmit={(e) => {
          e.preventDefault();
          const data: FormData = new FormData(e.target as HTMLFormElement);
          const values = Object.fromEntries(data.entries()) as Record<string, unknown>;
          const method = filterMehodOption[filterColumn["type"]].filter((method) => method["value"] === values["filterMethod"])[0];
          if (filterColumn["type"] === "number") {
            const digitsOnly = (values["filterText"] as string).replaceAll(",", "");
            if (digitsOnly === "") {
              if (method["filterValue"] === false) {
                //
              } else {
                toast.error("Please input filter value");
                return;
              }
            }
          }

          const filterRule = { ...values, "filterColumn": filterColumn, "filterMethod": method };
          setFilterRules([...filterRules, filterRule]);
        }}>
          <div>
            Choose column to filter:
            {
              columns.map((column, key) => (
                <div key={key} className="filter-popper-column">
                  <Radio name="filterColumn"
                    value={column['column']}
                    onChange={() => setFilterColumn(column)}
                    forceEnabled={true}>
                  </Radio>
                  &nbsp;&nbsp;{column['text']}
                </div>
              )
              )
            }
          </div>
          <div>
            Filter method:&nbsp;&nbsp;
            <Dropdown name="filterMethod"
              options={filterMehodOption[filterColumn["type"]]} 
              forceEnabled={true}/>
          </div>
          <div>
            Value to filter by:&nbsp;&nbsp;
            <input type="text" name="filterText" autoComplete="off" defaultValue={filterColumn["type"] === "date" ? format(new Date(), "yyyy-MM-dd") : ""} />
          </div>
          <div>
            <button type="submit">Filter</button>
          </div>
        </form>
        {
          excludeSearch.length > 0 ?
            <>
              <hr />
              <div>
                <table className="filter-table">
                  <tbody>
                    {
                      excludeSearch.map((rule, key) => (
                        rule["filterColumn"]["text"] ?
                          <tr key={key}>
                            <td>
                              <FontAwesomeIcon icon={faXmark} onClick={() => removeFilter(rule)} />
                            </td>
                            <td className="no-wrap">
                              {columns.find((column) => column["column"] === rule["filterColumn"]["column"])["text"]}
                            </td>
                            <td className="no-wrap">
                              {rule["filterMethod"]["text"]}
                            </td>
                            <td className="no-wrap">
                              {(false === rule["filterMethod"]["filterValue"]) ? "" : rule["filterText"]}
                            </td>
                          </tr>
                          :
                          null
                      )
                      )
                    }
                  </tbody>
                </table>
              </div>
              <div>
                <button type="button" onClick={() => removeFilter(null)}>Clear All Filters</button>
              </div>
            </>
            :
            null
        }
      </div>
    );
  };

  const Popover = (props: unknown) => {
    return (
      <div className="filter-popover">
        {props.children}
        {props.isOpen && props.content}
      </div>
    );
  };

  const removeFilter = (rule: Record<string, unknown> | null) => {
    if (rule == null) {
      setFilterRules([...filterRules.filter((rule) => !("text" in rule["filterColumn"]))]);
    } else if (!rule["filterColumn"]["text"]) {
      setFilterRules([...filterRules.filter((rule) => ("text" in rule["filterColumn"]))]);
    } else {
      const index = filterRules.indexOf(rule);
      if (index !== -1) {
        filterRules.splice(index, 1);
        setFilterRules([...filterRules]);
      }
    }
  }

  useEffect(() => {
    const filterMethods = {
      "Contains": (value: string, filter: string) => {
        if (value && filter && value.toUpperCase().indexOf(filter.toUpperCase()) !== -1) {
          return true;
        }
        return false;
      },
      "Equals": (value: string, filter: string) => {
        if (value && filter && value.toUpperCase() === filter.toUpperCase()) {
          return true;
        }
        return false;
      },
      "NotContains": (value: string, filter: string) => {
        if (value && filter && value.toUpperCase().indexOf(filter.toUpperCase()) === -1) {
          return true;
        }
        return false;
      },
      "NotEquals": (value: string, filter: string) => {
        if (value && filter && value.toUpperCase() !== filter.toUpperCase()) {
          return true;
        }
        return false;
      },
      "IsBlank": (value: string, _filter: string) => {
        if (value && value.length > 0) {
          return false;
        }
        return true;
      },
      "IsNotBlank": (value: string, _filter: string) => {
        if (value && value.length > 0) {
          return true;
        }
        return false;
      },
      "Before": (value: string, filter: string) => {
        if (value && filter) {
          const dtValue = parseISO(value);
          const dtFilter = parseISO(filter);
          if (dtValue < dtFilter) {
            return true;
          }
        }
        return false;
      },
      "After": (value: string, filter: string) => {
        if (value && filter) {
          const dtValue = parseISO(value);
          const dtFilter = parseISO(filter);
          if (dtValue > dtFilter) {
            return true;
          }
        }
        return false;
      },
      "=": (value: number, filter: number) => {
        if (value !== null && filter !== null && value === filter) {
          return true;
        }
        return false;
      },
      "NotEqualTo": (value: number, filter: number) => {
        if (value !== null && filter !== null && value !== filter) {
          return true;
        }
        return false;
      },
      ">": (value: number, filter: number) => {
        if (value !== null && filter !== null && value > filter) {
          return true;
        }
        return false;
      },
      "<": (value: number, filter: number) => {
        if (value !== null && filter !== null && value < filter) {
          return true;
        }
        return false;
      },
      "IsNull": (value: number, _filter: number) => {
        if (value === null) {
          return true;
        }
        return false;
      },
      "IsNotNull": (value: number, _filter: number) => {
        if (value !== null) {
          return true;
        }
        return false;
      },
    };

    const filterByRule = (rule: Record<string, unknown>) => {
      const filterColumn = rule["filterColumn"];
      const filterMethod = rule["filterMethod"];
      if (!filterColumn || !filterMethod) {
        return;
      }

      const filterText = rule["filterText"];
      let filter = filterText;
      if (filterColumn["type"] === "number") {
        const digitsOnly = filterText.replaceAll(",", "");
        filter = digitsOnly * 1;
      }

      getTenantSummaryState.tenantSummary.filter((ts) => ts["visible"] !== false).forEach((ts) => {
        let value = ts[filterColumn["column"]];
        if (filterColumn["fn"]) {
          value = filterColumn["fn"](value);
        }

        if (filterMethods[filterMethod["value"]](value, filter) === true) {
          ts["visible"] = true;
        } else {
          ts["visible"] = false;
        }
      });
    };

    setIsPopoverOpen(false);
    if (filterMissingMandatoryField) {
      if (getTenantSummaryState.tenantSummary) {
        getTenantSummaryState.tenantSummary.forEach((ts) => {
          ts["visible"] = false;
          summaryMandatoryFields.forEach((field) => {
            if (ts["leaseStatusCode"] !== "1" && (ts[field] === null || ts[field] === "")) {
              ts["visible"] = true;
            }
          });
        });
        filterRules.forEach((rule) => filterByRule(rule))
        setData(getTenantSummaryState.tenantSummary.filter((ts) => ts["visible"] !== false));
      }
    } else if (filterMissingMandatoryVacantField) {
      if (getTenantSummaryState.tenantSummary) {
        getTenantSummaryState.tenantSummary.forEach((ts) => {
          ts["visible"] = false;
          summaryMandatoryFields.forEach((field) => {
            if (ts["leaseStatusCode"] === "1" && field !== "leaseTypeCode" && (ts[field] === null || ts[field] === "")) {
              ts["visible"] = true;
            }
          });
        });
        filterRules.forEach((rule) => filterByRule(rule))
        setData(getTenantSummaryState.tenantSummary.filter((ts) => ts["visible"] !== false));
      }
    } else {
      if (getTenantSummaryState.tenantSummary) {
        getTenantSummaryState.tenantSummary.forEach((ts) => {
          ts["visible"] = true;
        });
        filterRules.forEach((rule) => filterByRule(rule))
        setData(getTenantSummaryState.tenantSummary.filter((ts) => ts["visible"] !== false));
      }
    }
    if (getTenantSummaryState.tenantSummary && getTenantSummaryState.tenantSummary.filter((ts) => ts["visible"] !== false).length === 0 && toastFiltering) {
      setFilterMissingMandatoryVacantField(false);
      setFilterMissingMandatoryField(false);
      setFilterRules([]);
      setToastFiltering(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterRules, getTenantSummaryState.tenantSummary]);

  const performSearch = (searchText, event) => {
    setCurrentSortingField("");
    if (searchText === "") {
      removeFilter(
        {
          filterColumn: { column: "tenantName", type: "text" },
          filterMethod: { text: "Contains", value: "Contains" },
          filterText: ""
        }
      );
      return;
    }

    const filterRule = {
      filterColumn: { column: "tenantName", type: "text" },
      filterMethod: { text: "Contains", value: "Contains" },
      filterText: searchText
    };
    setFilterRules([...filterRules, filterRule]);
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  // Debounce the search function with a delay of 500ms
  const debouncedSearch = debounce(performSearch, 500);
  const handleInputChange = (event) => {
    const query = event.target.value;
    debouncedSearch(query, event);
  };

  return (
    <div className="unit-table-container">
      <ToastContainer stacked containerId="lease-table-toast-container" position="top-right"
        autoClose={false} closeButton={true} hideProgressBar={true} progressStyle={{ "display": "none" }} />
      {showCommercialQuestion ?
        <div className="lease-info-commercial">
          <p>{t("commercial") as string}</p>
          <RadioButton name="commercial-yes" id="commercial-yes" text={t("confirm") as string} textPosition="left" value="true" onChange={() => {
            setIsCommercial(true)
            setProfileData({ ...profileData, hasCommercialFlag: true })
          }} currentValue={isCommercial} />
          <RadioButton name="commercial-no" id="commercial-no" text={t("cancel") as string} textPosition="left" value="false" onChange={() => {
            setIsCommercial(false)
            setProfileData({ ...profileData, hasCommercialFlag: false })
          }} currentValue={isCommercial} />
        </div> : <></>
      }
      <div className="disclaimer">
        <p>{t('disclaimer')}</p>
        <p className="imp-note">{t('mandatory-fields', { ns: 'common' })}</p>
      </div>
      <div className='unit-table-subcontainer'>
        <div className="unit-table-summary-wrapper">
          <div className="unit-table-header-row">
            <h3 className="unit-table-text">{t('table-name')}</h3>
            <div className="unit-table-control-group">
              {!isReadOnly && <Button onClick={() => setShowWizardModal(true)} text={<FontAwesomeIcon icon={faWandMagicSparkles} />} ariaLabel="guidedExperienceWizard" />}
              <Popover
                isOpen={isPopoverOpen}
                content={<div className="popover-content"><FilterPopover /></div>}
              >
                <ToolTip contents={<Button onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                  text={<FontAwesomeIcon icon={faFilter} />} ariaLabel="filter" forceEnabled={true} />}>
                  Tooltip on the filter button
                </ToolTip>
              </Popover>
              {/* <Button onClick={() => null} text={<FontAwesomeIcon icon={faArrowDownWideShort} />} ariaLabel="sort" /> */}
              {!isReadOnly && <ToolTip contents={<Button onClick={() => setShowAddUnitModal(true)} text={<FontAwesomeIcon icon={faPlus} />} ariaLabel="addUnit" />}>
                {t('tooltips.add-unit-tooltip')}
              </ToolTip>}
              {/* <Button onClick={() => null} text={<FontAwesomeIcon icon={faEyeSlash} />} ariaLabel="hideMatchedUnits" /> */}
              <input name="tenant-search" id="tenant-search" type="search"
                placeholder="Search by Tenant Name" className="tenant-search-input"
                onChange={(e) => handleInputChange(e)} autoComplete="off"
              />
            </div>
          </div>

          <DataTable headings={headings}
            className="unit-table"
            totalPages={Math.ceil(data.length / numberPerPage)}
            currentPage={currentPage}
            changePage={setCurrentPage}
            numberPerPage={numberPerPage}
            changeNumberPerPage={setNumberPerPage}>
            {buildTableContent()}
          </DataTable>
        </div>
      </div>
      {showAddUnitModal &&
        <Modal title={t('headings.add-unit')} onCloseModal={() => setShowAddUnitModal(false)}>
          <LoadingSpinner loading={createUnitState.loading}>
            <CommercialUnit unit={emptyUnit} onSave={(unit) => createUnit(unit)} onCancel={() => null} showDeleteBtn={false} />
          </LoadingSpinner>
        </Modal>}
      {showEditUnitModal &&
        <Modal title={currentUnit['tenantName'] as string} onCloseModal={() => setShowEditUnitModal(false)}>
          <LoadingSpinner loading={getIncomeUnitState.loading || deleteIncomeUnitState.loading || updateUnitState.loading} >
            <CommercialUnit unit={currentUnit}
              onSave={(unit) => updateUnit(unit)}
              onCancel={() => {
                removeCurrentUnit();
                setShowEditUnitModal(false);
              }}
              showDeleteBtn={!isReadOnly} />
          </LoadingSpinner>
        </Modal>}
      {showWizardModal && !isReadOnly &&
        <Modal title={t('headings.guided-experience-wizard')} onCloseModal={() => setShowWizardModal(false)}>
          <GuidedExperienceWizard snapshotId={currentSnapshotId} onCancel={() => setShowWizardModal(false)} importSnapshotId={importSnapshotId}/>
        </Modal>}
    </div>
  );
}
