import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/root';
import { GetElsLookupsState } from '../../redux/reducers/getLookups/getElsLookupsReducer';
import Checkbox from '../common/Checkbox/Checkbox';
import { Option } from '../common/Dropdown/Dropdown';
import CommercialUnitInputField from './CommercialUnitInputField';
import CommercialUnitDropdownField from './CommercialUnitDropdownField';
import "./CommercialUnit.css";

interface CommercialUnitInternalTableProps {
    formData: Record<string, unknown>,
    analysisFlagDisabled?: boolean,
}

export default function CommercialUnitInternalTable(props: CommercialUnitInternalTableProps): JSX.Element {
    const elsLookupsState: GetElsLookupsState = useSelector((state: RootState) => state.lookupsReducer.getElsLookups);
    const tenancyTypeOptions: Option[] = [{ value: "", text: "" }];
    const [dt, setDt] = useState<Option[]>([]);
    const isInternalUser = localStorage.getItem("isInternalUser");
    (elsLookupsState.dropdowns["tenancyType"] as Record<string, unknown>[]).forEach((option) => {
        if ((option["activeFlag"] as string) === "Y") {
            tenancyTypeOptions.push({ value: option["tenancyTypeCode"] as string, text: option["tenancyTypeDescription"] as string });
        }
    });

    let analysisFlagDisabled = props.analysisFlagDisabled;
    if (props.formData["leaseStatusCode"] === "1") {
        props.formData["analysisFlag"] = "N";
        analysisFlagDisabled = true;
    } else {
        analysisFlagDisabled = false;
    }

    useEffect(() => {
        if (props.formData.tenancyTypeCode) {
            const dtMappedBytt = (elsLookupsState.dropdowns["tenancyType"] as Record<string, unknown>[])
            .filter(tt => tt.tenancyTypeCode === props.formData.tenancyTypeCode)[0]["unitDesignTypeCode"] as string[];
            const dt = (elsLookupsState.dropdowns["designType"] as Record<string, unknown>[])
            .filter((dt) => dtMappedBytt.includes(String(dt.unitDesignTypeCode)));
            const mappedOptions: Option[] = [];
            dt.forEach((option) => {
                 if ((option["activeFlag"] as string) === "Y") {
                     mappedOptions.push({ value: option["unitDesignTypeCode"] as string, text: option["unitDesignTypeDescription"] as string });
                 }
             })
            setDt(mappedOptions);
        }
    }, [props.formData.tenancyTypeCode, elsLookupsState.dropdowns]);
    return (
        <table className='unit-table-section' role="none">
            <tbody>
                <CommercialUnitInputField type="text" name="buildingIdNumber"
                    formData={props.formData} errors={{}} heading='BIN' />
                <CommercialUnitDropdownField name='tenancyTypeCode' formData={props.formData} errors={{}}
                    heading={'Tenancy Type'} />
                <CommercialUnitDropdownField name='designTypeCode' formData={props.formData} errors={{}}
                    heading={'Design Type'} options={dt} />
                <CommercialUnitDropdownField name='variables' formData={props.formData} errors={{}}
                    heading={'Variables'} multiple={true}/>
                <tr>
                </tr>
                <tr>
                    <td style={{ width: "60px" }}>
                        <div>Analysis</div>
                        <div>
                            <select name="analysisFlag"
                                value={props.formData.analysisFlag as string}
                                disabled={analysisFlagDisabled}
                                onChange={() => null}>
                                {['Y', 'N', 'X', 'F'].map(analysis => (
                                    <option key={analysis} value={analysis}>
                                        {analysis}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className='flex flex-row align-items-center' >
                            <span className='label-cell padding-right-10'>
                                Link Flag
                            </span>
                            <Checkbox name="linkFlag"
                                defaultChecked={props.formData.linkFlag as boolean} disabled={isInternalUser === 'true'}/>
                        </div>
                    </td>
                </tr>
                <CommercialUnitInputField type="text" name="priority"
                    formData={props.formData} errors={{}} heading='Priority' />
            </tbody>
        </table>
    );
}
