import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import "./Toc.css"
import Button from "../Button/Button";


export default function Toc(): JSX.Element {
    const { t } = useTranslation("toc");

    const contentRef = useRef(null);

    const renderTocParagraph = () => {
        const arr = [];
        for (let i = 1; i <= 12; i++) {
            arr.push(<p className="toc-paragraph">{t("paragraph-" + i) as string}</p>);
        }
        return arr;
    }

    const printToc = () => {
        if (contentRef.current) {
            const contents = (contentRef.current as HTMLSpanElement).innerHTML;
            const frame = document.createElement("iframe");
            frame.name = "frame";
            frame.style.position = "absolute";
            frame.style.top = "-1000000px";
            document.body.appendChild(frame);
            const frameDoc = frame.contentWindow ? frame.contentWindow.document : frame.contentDocument;
            if (frameDoc) {
                frameDoc.open();
                frameDoc.write("<html><head><title>" + t("title") as string + "</title>");
                frameDoc.write("</head><body>");
                frameDoc.write(contents);
                frameDoc.write("</body></html>");
                frameDoc.close();
                setTimeout(function () {
                    frame.focus();
                    if (frame.contentWindow) {
                        frame.contentWindow.print();
                    }
                    document.body.removeChild(frame);
                }, 500);
            }
        }
        return false;
    }

    return (
        <div className="toc-container">
            <span className="toc-content" ref={contentRef}>
                {renderTocParagraph()}
                <p>{t("paragraph-13-1") as string} <a href={t("contact-link", { ns: "common" })} target="_blank" rel="noreferrer noopener">{t("hyperlink-1-text") as string}</a> {t("paragraph-13-2") as string} <a href={t("hyperlink-2") as string} target="_blank" rel="noreferrer noopener">{t("hyperlink-2-text") as string}</a>.</p>
            </span>
            <div className="toc-footer">
                <div className="toc-questions">
                    <p><b>{t("question-1") as string}</b><br></br><a href={t("contact-link", { ns: "common" })} target="_blank" rel="noreferrer noopener">{t("question-hyperlink-text") as string}</a> {t("question-2") as string}</p>
                </div>
                <div className="toc-print">
                    <Button
                        type="submit"
                        text={<p><FontAwesomeIcon icon={faPrint} /> {t("print") as string}</p>}
                        onClick={() => printToc()}
                    />
                </div>
            </div>
        </div>
    )
}