import React, { useEffect, useState } from 'react';
import {
	HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { generateRoutes } from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/reducers/root';
import { validateInternalToken, validateToken } from './redux/actions/auth/token';
import setSessionExpiry from './utils/sessionExpiry';
import { logout } from './redux/actions/auth/auth';
import { LogoutState } from './redux/reducers/getAuthReducer/logoutReducer';
import ValidateTokenState from './types/auth/ValidateTokenState';
import ValidateInternalTokenState from './types/auth/ValidateInternalTokenState';
import { toast } from 'react-toastify';
import { useValidationResponse } from './utils/context/FormErrorContext';
import { SaveResultState } from './redux/reducers/getSaveData/saveResultReducer';

function App(): JSX.Element {
	const dispatch = useDispatch()
	const [auth, setAuth] = useState(false);
	const [sessionExpired, setSessionExpired] = useState(false);
	const [requestSent, setRequestSent] = useState(false);
	const [internalReqsent, setInternalReqSent] = useState(false);
	const [isInternalUser, setIsInternalUser] = useState(false);
	const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
	const validateTokenStore = useSelector((state: RootState) => state.authReducer.validateToken as ValidateTokenState);
	const validateInternalTokenStore = useSelector((state: RootState) => state.authReducer.validateInternalToken as ValidateInternalTokenState);
	const logoutStore = useSelector((state: RootState) => state.authReducer.logout as LogoutState);
	const internalUserVariable = process.env.REACT_APP_IS_INTERNAL_USER;
	const isLocalVariable = process.env.REACT_APP_IS_LOCAL;

	useValidationResponse(saveResultState.result ?? []);
	useEffect(() => {
		console.log("Internal user variable: ", internalUserVariable);
		console.log("Is local variable: ", isLocalVariable);
		// eslint-disable-next-line
		if (internalUserVariable == "0") {
			// Validate amp token only for external users
			// eslint-disable-next-line
			if (isLocalVariable == "0") {
				const splitRoute = window.location.href.split('/')
				const localToken = window.localStorage.getItem("authToken")
				const alreadyHasToken = localToken !== null && setSessionExpiry() > 0
				const token = alreadyHasToken ? localToken : splitRoute[splitRoute.length - 1];
				console.log("External token", token);

				if(token) {
					const parsedToken = alreadyHasToken ? token : token.split('?')[0]
					dispatch(validateToken(parsedToken));
					setRequestSent(true);
				}
				else {
					toast.error("Not Authorized, redirecting...");
					window.location.href = `${process.env.REACT_APP_AMP_URL}#/auth/home/reg-user`;
				}
			}
			else {
				dispatch(validateToken(process.env.REACT_APP_LOCAL_TOKEN as string));
				setRequestSent(true);
			}
		}
		// eslint-disable-next-line
		else if (internalUserVariable == "1") {
			const splitRoute = window.location.href.split('/');
			const token = splitRoute[splitRoute.length - 2];
			const roll = splitRoute[splitRoute.length - 1];
			console.log("Internal token", token);
			console.log("Internal roll", roll);
			localStorage.setItem("rollNumber", roll);

			if (!internalReqsent) {
				dispatch(validateInternalToken(token));
				setInternalReqSent(true);
			}
		}
	// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (logoutStore.requested) {
			setSessionExpired(true);
		}
	}, [logoutStore.requested]);

  useEffect(() => {
    if(requestSent) {
      if(validateTokenStore.valid) {
        validateTokenStore.token && window.localStorage.setItem("authToken", validateTokenStore.token);
        validateTokenStore.exp && window.localStorage.setItem("sessionExp", String(validateTokenStore.exp));
        setAuth(true);
        setRequestSent(false);
      }
      else if (validateTokenStore.error) {
        toast.error(validateTokenStore.message);
				// Don't redirect to amp in local environment
				// eslint-disable-next-line
				if (isLocalVariable == '0') {
					window.location.href = `${process.env.REACT_APP_AMP_URL}/#/auth/home/reg-user`;
				}
      }
    }
  }, [validateTokenStore, requestSent, isLocalVariable]);


	useEffect(() => {
    if(internalReqsent) {
			if (validateInternalTokenStore.success) {
				localStorage.setItem("authToken", validateInternalTokenStore.admin_user_token as string);
				localStorage.setItem("username", validateInternalTokenStore.username as string);
				localStorage.setItem("sessionExp", String(validateInternalTokenStore.exp))
				localStorage.setItem('isInternalUser', "true");
				setIsInternalUser(true);
				setAuth(true);
				setInternalReqSent(false);
			}
			else if (validateInternalTokenStore.error) {
				toast.error(validateInternalTokenStore.message as string);
				window.location.href = `${process.env.REACT_APP_INTERNAL_PORTAL_URL}#/login`;
			}
    }
  }, [validateInternalTokenStore, internalReqsent]);

	const logoutUser = () => {
		dispatch(logout())
		localStorage.clear();
		setAuth(false);
		window.location.href = `${process.env.REACT_APP_AMP_URL}/#/auth/home/reg-user`;
	}
	
	// Session only in live environment
	// eslint-disable-next-line
	if (isLocalVariable== "0" && localStorage.getItem("sessionExp") != undefined) {
		const timeLeft = setSessionExpiry();
		if (timeLeft <= 0) {
			if (!sessionExpired) {
				setSessionExpired(true);
				toast.error("Session expired");
				logoutUser();
			}
		} else {
			if (sessionExpired) {
				setSessionExpired(false);
			}
			setTimeout(() => {
				setSessionExpired(true);
				logoutUser();
			}, timeLeft );
		}
	}

	const routes = generateRoutes(auth, sessionExpired);
	return (
		<>
			<main>
				<Router>
					<Routes>
						{routes}
						<Route path="*"
						element={auth && (isInternalUser ? <Navigate to={"/internal-landing"} /> : <Navigate to={"/landing"} />)} />
					</Routes>
				</Router>
			</main>
		</>
	)
}

export default App;