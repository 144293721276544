/**
 * Author: Mariam Bawa (mariam.bawa@mpac.ca)
 * Date Created: April 18, 2024
 * Context: Utility function created to generate table content for income attributes. Works with objects of type incomeAttributeLookupObject
 */

import React, { Fragment } from "react";
import IncomeAttributeLookupObject from "../../types/lookups/IncomeAttributeLookupObject";
import { useTranslation } from "react-i18next";
import Input from "../../components/common/Input/Input";
import CurrencyInput from "../../components/common/CurrencyInput/CurrencyInput";
import Asterisk from "../../components/common/Asterisk/Asterisk";
import PercentInput from "../../components/common/PercentInput/PercentInput";
import ToolTip from "../../components/common/ToolTip/ToolTip";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/root";
import { ProfileOverviewState } from "../../redux/reducers/getProfile/getProfileOverviewReducer";
import MenuState from "../../types/menu/MenuState";

interface AttributeTableFragmentProps {
  attribute: IncomeAttributeLookupObject,
  data: Record<string, unknown> | undefined,
  showSubheading: boolean,
  isOtherLabel: boolean,
  hasInputLabel: boolean,
  isPlaceHolderWithAttrDesc?: boolean,
  isRequiredField: boolean,
  isReplacementReserve?: boolean,
  isCapitalCostsOrReplacementReserve?: boolean,
  handleCommentChange: (value: string, name: string) => void,
  valueChangeHandler?: (newAmt: number | undefined | null, name: string, oldAmt: number | undefined | null) => void,
  errors?: Record<string, string>,
  fieldType?: "percent"
}
export default function IncomeAttributeTableFragmentGenerator(props: AttributeTableFragmentProps): React.ReactNode {
  const { i18n } = useTranslation();
  const { t } = useTranslation("pie");
  const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
  const menuState = useSelector((state: RootState) => state.menuReducer.setMenu as MenuState);
  const activeMenuName = menuState.activeItem.name;
  const { attribute, data, showSubheading, isOtherLabel, hasInputLabel, isRequiredField, isReplacementReserve, isCapitalCostsOrReplacementReserve } = props;

  let attrDesc = "";
  i18n.language === 'fr' ? attrDesc = attribute.incomeAttributeFrenchDescription : attrDesc = attribute.incomeAttributeDescription
  let tooltip = t("tooltip." + profileOverview.profile.profile_type_code + "." + attribute.incomeAttributeCode);
  if (tooltip.startsWith("tooltip.")) {
    tooltip = attrDesc;
  }

  return (
    <Fragment key={attribute.incomeAttributeCode}>
      {showSubheading && (
        <tr className="sub-th">
          <td colSpan={2} className='label' headers="attribute-label">
            {isOtherLabel ? t("other") : attrDesc}
          </td>
        </tr>
      )}
      <tr>
        {hasInputLabel ?
          (
            props.isPlaceHolderWithAttrDesc ?
              (
                <td headers="attribute-label">
                  <ToolTip contents={
                    <Input type="text" name={"income-attribute-comment-" + attribute.incomeAttributeCode}
                      id={"income-attribute-comment-" + attribute.incomeAttributeCode}
                      placeholder={attrDesc + (attrDesc ? " " : "") + t("comment")} title={tooltip}
                      value={data && data["description"] as string}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleCommentChange(e.target.value, e.target.name)}
                      autoComplete="off"
                    />
                  }>
                    {tooltip}
                  </ToolTip>
                </td>
              )
              :
              (
                <td headers="attribute-label">
                  <ToolTip contents={
                    <Input type="text" name={"income-attribute-comment-" + attribute.incomeAttributeCode}
                      id={"income-attribute-comment-" + attribute.incomeAttributeCode}
                      placeholder={t("comment")} title={tooltip}
                      value={data && data["description"] as string}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.handleCommentChange(e.target.value, e.target.name)}
                      autoComplete="off"
                    />}>
                    {tooltip}
                  </ToolTip>
                </td>
              )
          )
          :
          (<td className="label" headers="attribute-label">
            {isCapitalCostsOrReplacementReserve ? (i18n.language === 'fr' ? attribute.incomeAttributeFrenchDescription : attribute.incomeAttributeDescription) :
              <ToolTip contents={(i18n.language === 'fr' ? attribute.incomeAttributeFrenchDescription : attribute.incomeAttributeDescription)}>
                {tooltip}
              </ToolTip>
            }
            {isRequiredField && <>&nbsp;<Asterisk /></>}
          </td>)}
        <td headers="attribute-value" className="value">
          {props.fieldType === "percent" ?
            <PercentInput name={"income-attribute-" + attribute.incomeAttributeCode}
              onChange={props.valueChangeHandler}
              value={data && data["percent"] as number}
              errormessage={props.errors ? props.errors[attribute.incomeAttributeCode] : undefined} />
            : <CurrencyInput name={"income-attribute-" + attribute.incomeAttributeCode}
              onChange={props.valueChangeHandler}
              value={data && data["value"] as number}
              errormessage={props.errors ? props.errors[attribute.incomeAttributeCode] : undefined} />
          }

        </td>
      </tr>

      {isReplacementReserve && (
        <>
          <tr className="sub-th">
            <td colSpan={2} className='label' headers="attribute-label">
              &nbsp;
            </td>
          </tr>
          <tr title={t("tooltip." + activeMenuName + ".items")}>
            <th className='label' headers="attribute-label">
              {t(activeMenuName + ".items")}
            </th>
            <th className="text-center">
              {t(activeMenuName + ".amount")}
            </th>
          </tr>
        </>
      )
      }

    </Fragment>
  );
}